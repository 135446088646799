import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Backdrop from "../../Backdrop/Backdrop";
import ModalConfirm from "../../Modal/ModalConfirm";

import { useStore } from "../../../hook-store/store";

import { BACKEND_URL } from "../../../App";

import { marks } from "../../../utils/marks";

import classes from "./EmptyTrashbox.module.css";

function EmptyTrashbox(props) {
  const { trashObjects, deleteFilesFoldersHandler } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  const {
    bucketName,
  } = store.bucketObjectStore;
  const { gLoading } = store.uiStore;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);

  return (
    <Fragment>
      <div className={classes.emptyTrashboxRow}>
        <div className={classes.emptyTrashboxButton}>
          <button className="btnBase"
            onClick={() => {
              if (!isLoading) {
                setShowConfirmModal(true);
              }
            }}
          >
            empty trash box
          </button>
        </div>
      </div>

      {showConfirmModal && (
        <div>
          <Backdrop
            zIndex={"110"}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            onCancel={() => {
              if (!isLoading) {
                setShowConfirmModal(false);
              }
            }}
          />
          <ModalConfirm
            hideButtons={true}
            onClose={() => {
              setShowConfirmModal(false);
            }}
            onAccept={() => {
              if (!isLoading) {
              }
            }}
            isLoading={isLoading}
          >
            <div>
              <div className={classes.emptyTrashboxRow}>
                delete all ?
              </div>
              <div className={classes.emptyTrashboxRow}>
                <div className={classes.emptyTrashboxButtons}>
                  <button className="btnBase"
                    onClick={() => {
                      if (!isLoading) {
                        setShowConfirmModal(false);
                      }
                    }}
                  >
                    cancel
                  </button>
                  <button className="btnBase"
                    onClick={() => {
                      if (!isLoading) {
                        deleteFilesFoldersHandler(
                          bucketName,
                          trashObjects,
                          trashObjects
                        );
                      }
                    }}
                  >
                    delete
                  </button>
                </div>
              </div>
            </div>
          </ModalConfirm>
        </div>
      )}
    </Fragment>
  );
}

export default EmptyTrashbox;
