import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import EmptyTrashbox from './EmptyTrashbox';
import TrashboxList from './TrashboxList/TrashboxList';
import TrashMenuForWide from './TrashMenu/TrashMenuForWide';
import TrashPrefixLine from './TrashPrefixLine';
import TrashSelectedControl from './TrashSelectedControl/TrashSelectedControl';

import { useStore } from '../../../hook-store/store';
import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import { 
  // updateTrashMetadata, 
  deleteFileTrashMetadata,
  deleteFolderTrashMetadata,
  getObjectMetadata,
  getMetadataFromMetaList,
  getParsedMetadataFromObjectMeta,
  createParentPrefixList,
 } from '../../../utils/bucket/object-metadata-util';

import { BACKEND_URL } from '../../../App';

import { marks } from '../../../utils/marks';

import classes from '../BucketObject/BucketObjectList.module.css';
import { createDeletedObjectList } from '../../../utils/bucket/file-move-util';

// import { update } from 'lodash';

// import ObjectControl from '../ObjectControl/ObjectControl';

function Trashbox(props) {

  const {
    getAllBucketObjects,
    storeObjectAccessHandler,
    createToplevelsHandler,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const { 
    selectedFileKeys,
    movePrefixes,
    // topLevels,
    // accessList,
    allBucketObjects,
    bucketName,
  } =store.bucketObjectStore;
  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;
  // const windowValues = store.uiStore.windowValues;

  const [trashObjects, setTrashObjects] = useState([]);
  const [selectedTrashObjects, setSelectedTrashObjects] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState();
  // const [existDestKeys, setExistDestKeys] = useState([]);
  // const [isAllSelected, setIsAllSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  useEffect(() => {
    dispatch('SET_CURRENT_PREFIX', '');
  },[]);

  useEffect(() => {
    if (allBucketObjects.length > 0) {
      createTrashList(allBucketObjects);
    }
  },[allBucketObjects]);


  useEffect(() => {
    setSelectedTrashObjects([]);
  },[selectedFolder]);
  
 

  const createTrashList = (allBucketObjects) => {
    const trashList = [];

    for (const object of allBucketObjects) {
      const metaData = getMetadataFromMetaList(object.Key, allObjectsMetaList);

      if (metaData && metaData.parsedMetadata.location === 'trashbox') {
        trashList.push({
          ...object,
          objectMeta: metaData.objectMeta,
          parsedMetadata: getParsedMetadataFromObjectMeta(metaData.objectMeta),
        })
      }

    }

    setTrashObjects(trashList);
    console.log('trashList', trashList);
  };


  const editSelectedTrashObjects = (object) => {
    const isInSelectedList = selectedTrashObjects.find(obj => {
      return obj.Key === object.Key;
    });

    if (isInSelectedList) {
      const deletedList = selectedTrashObjects.filter(obj => {
        return obj.Key !== object.Key;
      });

      setSelectedTrashObjects(deletedList);

    } else {
      setSelectedTrashObjects(selectedTrashObjects.concat(object));
    }
  };





  const selectObjectHandler = (key, trashList) => {
    console.log(key);
    if (!key) {
      setSelectedFolder(null);
    }

    if (key.endsWith('/')) {
      const object = trashList.find(element => element.Key === key);
      setSelectedFolder(object);
    }
  };

 

  const createRestoreObjectList = (objectList, trashList) => {
    let restoreList = [];

    for (const object of objectList) {
      if (object.Key.endsWith('/')) {
        const samePrefixObjects = trashList.filter(element => {
          return element.Key.startsWith(object.Key);
        });

        restoreList = restoreList.concat(samePrefixObjects);
      
      } else {
        restoreList = restoreList.concat([object]);
      }
    }

    
    restoreList = _.uniqBy(restoreList, 'Key');

    //// restore first upper folders then files  
    restoreList = _.orderBy(restoreList, function(o) {
      return o.Key.split('/').length;
    });

    const folderList = restoreList.filter(ele => {
      return ele.Key.endsWith('/');
    });

    const fileList = restoreList.filter(ele => {
      return !ele.Key.endsWith('/');
    });

    restoreList = folderList.concat(fileList);
    

    console.log('restoreList', restoreList);
    return restoreList;
  };




  const deleteTrashboxMetaHandler = async (objects, allObjectsMetaList) => {
    try {
      dispatch('SET_GLOADING', true);

      const restoreObjects = createRestoreObjectList(objects, trashObjects);
      console.log(objects, restoreObjects);

      // throw new Error('error-error');



      let deleteMetaResults = [];
      let forMetaUpdateList = allObjectsMetaList;

      for (const object of restoreObjects) {
        
        // throw new Error('error-error');
        
        let oldMetadata;

        
        const objectMeta = allObjectsMetaList.find(element => {
          return element.Key === object.Key;
        });
  
        if (objectMeta) {
          oldMetadata = objectMeta
        }
        
          
        // const objectMeta = await getObjectMetadata(
        //   BACKEND_URL,
        //   localStorage.getItem('token'),
        //   bucketName,
        //   object.Key,
        // );
        
        // if (objectMeta) {
        //   oldMetadata = objectMeta.data;
        // }
    
  
        console.log(oldMetadata);
    
        if (oldMetadata) {
          console.log('oldMetadata', oldMetadata);
          const parsedOldMeta = JSON.parse(oldMetadata.objectMeta.Metadata.metastring);
          const updateMeta = {
            ...parsedOldMeta,
            location: '',
            trashMoveTime: 0,
          };
    
          console.log(updateMeta);
    
          // continue;

          let resData;

          if (object.Key.endsWith('/')) {
            resData = await deleteFolderTrashMetadata(
                BACKEND_URL,
                localStorage.getItem('token'),
                bucketName,
                null,
                object.Key,
                updateMeta,
                dispatch,
              );

          } else {
            resData = await deleteFileTrashMetadata(
              BACKEND_URL,
              localStorage.getItem('token'),
              bucketName,
              null,
              object.Key,
              updateMeta,
              dispatch,
            );
          }
          
            console.log(resData);

            if (resData.data) {
              deleteMetaResults =  deleteMetaResults.concat(resData.data);
            
              const noOldObjectMetaList = forMetaUpdateList.filter(element => {
                return element.Key !== object.Key;
              });

              forMetaUpdateList = noOldObjectMetaList.concat({
                Key: resData.data.Key,
                objectMeta: resData.data.objectMeta,
              });

              dispatch('SET_ALLOBJECTSMETALIST', forMetaUpdateList);

            }

        }
      }


      dispatch('SET_OBJECTACTION', {
        actionType: 'update-metadata',
        delete: true,
        add: true,
        result: deleteMetaResults,
      });

      setSelectedTrashObjects([]);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'restored from trash box',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

    
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'restore failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };



  const deleteFilesFoldersHandler = async (bucket, objects, trashObjects) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        objectsDelete: null,
      }

      console.log('delete file folder num', objects.length);


      if (objects.length > 0) {

        const deleteObjects = createRestoreObjectList(objects, trashObjects);
        const keys = [];

        for (const object of deleteObjects) {
          keys.push(object.Key);
        }
        // await deleteBucketObjectsHandler(bucket, keys);

        if (!bucket || !keys || keys.length === 0) {
          throw new Error('bucket and keys are required');
        };
  
        console.log(keys, bucket);

        // throw new Error('error-error');
        const resData = await bucketObjectUtils.deleteBucketObjects(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          keys,
        );
  
        console.log(resData);
        
        resultsForAction.objectsDelete = resData;
  
        // deletedList = fileMoveUtils.createDeletedObjectList(keys, topLevels);
        // dispatch('SET_TOPLEVELS', deletedList);
        // dispatch('SET_SELECTED_FILEKEYS',[]);

        const notDeletedTrashObjects = [];
        
        for (const trashObject of trashObjects) {
          const isInDeleteList = keys.find(key => {
            return key === trashObject.Key;
          });

          if (!isInDeleteList) {
            notDeletedTrashObjects.push(trashObject);
          }
        }

        setTrashObjects(notDeletedTrashObjects);

        setSelectedTrashObjects([]);
        // setSelectedFolder(null);

      }


      dispatch('SET_OBJECTACTION', {
        actionType: 'delete-selected-objects',
        delete: true,
        add: false,
        result: resultsForAction,
      });


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'deletion success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      
      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `deletion failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };






  // console.log(createDisplayObjects(selectedFolder, trashObjects));



  return (
    <Fragment>
      <div>

        <div className={classes.objectTableView}>
          
          <div className={classes.trashboxTitle}>
            {t('trash.01', 'Trash box contents')}
          </div>

          {trashObjects.length > 0 && (
            <div>
              <EmptyTrashbox 
                trashObjects={trashObjects}
                deleteFilesFoldersHandler={deleteFilesFoldersHandler}
              />
            </div>
          )}

          {selectedTrashObjects.length > 0 && (
            <TrashSelectedControl
              trashObjects={trashObjects}
              selectedTrashObjects={selectedTrashObjects}
              setTrashObjects={setTrashObjects}
              setSelectedTrashObjects={setSelectedTrashObjects}
              deleteTrashboxMetaHandler={deleteTrashboxMetaHandler}
              deleteFilesFoldersHandler={deleteFilesFoldersHandler}
            />
          )}

          <div>
            <TrashPrefixLine 
              selectedFolder={selectedFolder}
              trashObjects={trashObjects}
              selectObjectHandler={selectObjectHandler}
            />
          </div>

          <TrashboxList 
            trashObjects={trashObjects}
            selectedFolder={selectedFolder}
            selectedTrashObjects={selectedTrashObjects}
            selectObjectHandler={selectObjectHandler}
            editSelectedTrashObjects={editSelectedTrashObjects}
            deleteTrashboxMetaHandler={deleteTrashboxMetaHandler}
            deleteFilesFoldersHandler={deleteFilesFoldersHandler}
            createRestoreObjectList={createRestoreObjectList}
          />
        
        </div>
      </div>

    </Fragment>
  );
}

export default Trashbox;
