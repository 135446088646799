import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import FolderList from './FolderList';
import SelectedObjects from '../SelectedObjects/SelectedObjects';
import SelectedObjectsActions from '../SelectedObjects/SelectedObjectsActions';
import { useStore } from '../../../hook-store/store';

import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';

import { marks } from '../../../utils/marks';
import { BACKEND_URL } from '../../../App';

import classes from './Folder.module.css';

function Folder(props) {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-Folder.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const prefixList = store.bucketObjectStore.prefixList;
  const selectedPrefix = store.bucketObjectStore.selectedPrefix;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefixes = store.bucketObjectStore.movePrefixes;

  const { allFolderList } = store.bucketObjectStore;
  
  const gLoading = store.uiStore.gLoading;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // useEffect(() => {
  //   if (bucketName && !currentPrefix && prefixList.length === 0) {
  //     // getAllBucketObjects(bucketName, '');
  //     getBucketCommonPrefixes(bucketName);
  //   }
  // },[bucketName, currentPrefix]);

  // useEffect(() => {
  //   if (bucketName && prefixList.length === 0) {
  //     getBucketCommonPrefixes(bucketName);
  //   }
  // },[bucketName, prefixList]);
  
    useEffect(() => {
    const sorted = _.orderBy(allFolderList, ['Key'],['asc']);
    // console.log(sorted);
    // setPrefixList(combineList);
    const sortedPrefixList = sorted.map(ele => {
      if (ele.Key.split('/').length === 2) {
        return {
          Prefix: ele.Key,
          hide: false,
        }
      } else {
        return {
            Prefix: ele.Key,
            hide: true,
        }
      }
    });

    dispatch('SET_PREFIXLIST', sortedPrefixList);

  },[allFolderList]);

  // const setCurrentPrefixHandler = (prefix) => {
  //   console.log(prefix); 
  //   // setCurrentPrefix(prefix);
  //   dispatch('SET_CURRENT_PREFIX', prefix);
  // };

  const selectedPrefixHandler = (prefix) => {
    if (prefix === selectedPrefix) {
      // setSelectedPrefix('');
      dispatch('SET_SELECTED_PREFIX', '');
    } else {
      // setSelectedPrefix(prefix);
      dispatch('SET_SELECTED_PREFIX', prefix);
    }

  };

  // const hideSelectPrefixHandler = (prefix) => {
  //   console.log(prefix);

  //   // const isTopLevel = prefix.split('/').length === 2;

  //   const prefixes2 = prefixList;

  //   for (const px of prefixes2) {
  //     // console.log(px.Prefix, px.Prefix.split('/'), prefix.split('/'));
  //     if (px.Prefix.startsWith(prefix) && px.Prefix.split('/').length === prefix.split('/').length + 1) {
  //       // console.log(px, px.Prefix.split('/'), prefix.split('/') + 1);
  //       px.hide = !px.hide;
  //     }
  //     if (px.Prefix.startsWith(prefix) && px.Prefix.split('/').length > prefix.split('/').length + 1) {
  //       // console.log(px, px.Prefix.split('/'), prefix.split('/') + 1);
  //       px.hide = true;
  //     }
  //   }

  //   console.log(prefixes2);
  //   dispatch('SET_PREFIXLIST', prefixes2);

  // };




  const getBucketCommonPrefixes = async (bucket) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await bucketObjectUtils.getBucketCommonPrefixes(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
      );

      console.log(resData);

      const addList = [];
      const gotPrefixes = resData.data.bucketObjects.allCommonPrefixes;
      const previousList = prefixList;

      for (const prefix of gotPrefixes) {
        const isInList = previousList.find(element => {
          return element.Prefix === prefix.Prefix;
        });

        if (!isInList) {
          // addList.push(prefix);
          addList.push({
            Prefix: prefix.Prefix,
            hide: false,
            // clickNum: 0,
            // getPrefixes: false,
            // open: false,
          })
        }
      }

      if (addList.length > 0) {
        const combineList = previousList.concat(addList);
        const sorted = _.orderBy(combineList, ['Prefix'],['asc']);
        console.log(sorted);
        // setPrefixList(combineList);
        dispatch('SET_PREFIXLIST', sorted);
        // setDisplayList(sorted);
      }


      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  const getPrefixesHandler = async (bucket, prefix) => {
    try {

      //// stop if already get same Prefix
      const samePrefixes = prefixList.filter(pfx => {
        return pfx.Prefix.startsWith(prefix);
      });

      console.log(samePrefixes);

      
      if (samePrefixes.length >= 2) {
        return;
      }


      //// stop if already get prefixes previously
      const prefixObj = prefixList.find(pfx => {
        return pfx.Prefix === prefix;
      });

      if (prefixObj.getPrefixes) {
        return;
      }


      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await bucketObjectUtils.getAllBucketPrefixes(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        prefix,
      );

      console.log(resData);

      const addList = [];
      const gotPrefixes = resData.data.prefixList.allKeys;
      const previousList = prefixList;

      for (const prefix of gotPrefixes) {

        const isInList = previousList.find(element => {
          return element.Prefix === prefix.Key;
        });

        if (!isInList) {
          // addList.push(prefix);
          addList.push({
            Prefix: prefix.Key,
            hide: true,
            // clickNum: 0,
            getPrefixes: true,
            // open: false,
          })
        }
      }

      //// set getPrefixes in object
      const prefixIndex = previousList.findIndex(ele => {
        return ele.Prefix === prefix;
      });

      previousList[prefixIndex] = {
        ...previousList[prefixIndex],
        getPrefixes: true,
      }

      if (addList.length > 0) {
        const combineList = previousList.concat(addList);
        const sorted = _.orderBy(combineList, ['Prefix'],['asc']);
        console.log(sorted);
        // setPrefixList(combineList);
        dispatch('SET_PREFIXLIST', sorted);
        // setDisplayList(sorted);
      }

      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };



  const topLevelName = 'top';
  const prefixArrayLabel = `${t('selectedObject.08', 'Destination Folder')}:`;
  
  let prefixArray =  (
    <span
      onClick={() => { 
        if (!isLoading) {
          selectedPrefixHandler(''); 
        }
      }}
    >
      {/* top */}
      {prefixArrayLabel}
      {' '}
      <span style={{fontWeight:"bold"}}>
        {topLevelName}
      </span>
    </span>
  );

  if (selectedPrefix) {
    prefixArray = (
      <span>
        <span>{prefixArrayLabel}</span>
        {' '}
        <span>{topLevelName} /</span>
        {' '}
        {selectedPrefix.split('/').map((px, index) => {
          if (index <= selectedPrefix.split('/').length - 3) {
            return (
              <span key={px}>
                {/* <span> {px} </span> */}
                <span> {decodeURI(px)} </span>
                <span>{'/'} </span>
              </span>
            );
          } else {
            return (
              <span style={{fontWeight:"bold"}}>
                {/* {px} */}
                {decodeURI(px)}
              </span>
            );
          }
        })}
      </span>
    );
  }


  return (
    <div >
      {/* <button onClick={() => { getPrefixesHandler(bucketName, currentPrefix) }}>get-prefixes-test</button> */}

      {/* <div>bucket-name: {bucketName}</div>
      <div>currentPrefix: {currentPrefix}</div>
      <div>selectedPrefix: {selectedPrefix}</div> */}

      <SelectedObjects />

      <div>{t('selectedObject.07', 'Select Destination Folder')}</div>

      {/* {currentPrefix && (
        <button onClick={() => {
          getFolderContents(bucketName, currentPrefix);
        }}>get-folder-top-level-contents</button>
      )} */}

      {/* <div>prefix-array-body</div>
      {prefixArrayBody} */}
      {/* <div>toplevels-body</div>
      {topLevelsBody} */}
      
      {/* <div>folders-Body</div> */}
      {/* <table>
        {foldersBody}
      </table> */}
      <FolderList 
        getPrefixesHandler={getPrefixesHandler}
        selectedPrefixHandler={selectedPrefixHandler}
        isLoading={isLoading}
      />

      <div className={classes.targetFolderLine}>{prefixArray}</div>

      <SelectedObjectsActions />
    </div>
  );
}

export default Folder;
