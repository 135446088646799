import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';


// import CreateFolder from '../ObjectControl/CreateFolder';
import FileActions from './FileAction/FileActions';
import FileNameChange from './FileAction/FileNameChange';
// import FilesUpload from '../ObjectControl/FilesUpload';
import FolderActions from './FolderAction/FolderActions';
import FolderNameChange from './FolderAction/FolderNameChange';
import PrefixLine from './PrefixLine';
import SelectedObjectsControll from '../SelectedObjects/SelectedObjectsControl';
// import SelectedObjectsDelete from '../SelectedObjects/SelectedObjectsDelete';
// import SelectedObjectsActions from '../SelectedObjects/SelectedObjectsActions';
import { useStore } from '../../../hook-store/store';

import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import * as fileMoveUtils from '../../../utils/bucket/file-move-util';
import { 
  createFileMark,
  createSortedTopLevels,
} from '../../../utils/bucket/bucket-ui-util';
// import { createTokenForTools } from '../../../utils/bucket/object-for-tools';

import { marks } from '../../../utils/marks';

import BucketObjectMenu from './BucketObjectMenu';
import Backdrop from '../../Backdrop/Backdrop';
import ModalPosition from '../../Modal/ModalPosition';

// import { kuraToolsLink } from '../../../App';

import classes from './BucketObjectList.module.css';

// import ObjectControl from '../ObjectControl/ObjectControl';

function BucketObjectList(props) {

  const {
    getAllBucketObjects,
    deleteBucketObjectsHandler,
    getBucketCommonPrefixes,
    getFolderContents,
    getObjectSignedUrlHandler,
    // fileDownloadHandler,
    deleteFolderHandler,
    objectSignedUrl,
    storeObjectAccessHandler,
    createToplevelsHandler,
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const checkMark = marks.checkMark;
  const noCheckMark = marks.noCheckMark;

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  // const bucketName = store.bucketObjectStore.bucketName;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefix = store.bucketObjectStore.movePrefix;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;
  // const reservedPrefixes = store.bucketObjectStore.reservedPrefixes;

  const { 
    allBucketObjects,
    searchSelectedItem,
  } = store.bucketObjectStore;

  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;
  // const windowValues = store.uiStore.windowValues;

  const [clickedObjectKey, setClickedObjectKey] = useState('');
  const [clickedActionInfo, setClickedActionInfo] = useState();
 
  const [modalPositions, setModalPositions] = useState({ 
    top: null, 
    left: null,
    right: null,
    bottom: null,
    heightLimit: 300,
  });

  const [isLoading, setIsLoading] = useState(false);

  let isAllSelected = false;
  if (topLevels.length >= 2 && 
      (selectedFileKeys.length + movePrefixes.length) >= topLevels.length -1 
  ) {
    isAllSelected = true;
  }


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    console.log('clickedActionInfo', clickedActionInfo);
  },[clickedActionInfo]);

  useEffect(() => {
    setClickedObjectKeyHandler(''); 
    setClickedActionInfo(null);
  },[topLevels]);

  const selectedFileKeysHandler = (inputKey, action, selectedFileKeys) => {
    const newKeys = fileMoveUtils.changeSelectedFileKeys(
      inputKey, 
      action, 
      selectedFileKeys
    );
    dispatch('SET_SELECTED_FILEKEYS', newKeys);
  };

  const setMovePrefixHandler = (prefix) => {
    if (movePrefix === prefix) {
      dispatch('SET_MOVEPREFIX', '');
    } else {
      dispatch('SET_MOVEPREFIX', prefix);
    }
  };

  const setMovePrefixesHandler = (prefix, action) => {
    const currentMovePrefixes = movePrefixes;
    const isInList = currentMovePrefixes.find(px => {
      return px === prefix;
    });

    if (!isInList && action === 'add') {
      const added = currentMovePrefixes.concat(prefix);
      dispatch('SET_MOVEPREFIXES', added);
    } 

    if (isInList && action === 'delete') {
      const deleted = currentMovePrefixes.filter(px => {
        return px !== prefix;
      });

      dispatch('SET_MOVEPREFIXES', deleted);
    }

  };

  const setClickedObjectKeyHandler = (key) => {
    if (key === clickedObjectKey) {
      setClickedObjectKey('');
    } else {
      setClickedObjectKey(key);
    }
    // console.log(key);
  };

  const setClickedActionInfoHandler = (event, key) => {
    if (key) {
      setClickedActionInfo({
        name: event.target.name,
        key: key,
        date: Date.now(),
      });
    } else {
      setClickedActionInfo(null);
    }
  }

  const folderClickSelectHandler = (isInMovePrefixes, prefix) => {
    if (isInMovePrefixes) {
      setMovePrefixesHandler(prefix, 'delete');
    }
    else {
      setMovePrefixesHandler(prefix, 'add');
    }
  };

  const fileClickSelectHandler = (event, isInSelectedFileKeys, key) => {
    if (isInSelectedFileKeys) {
      selectedFileKeysHandler(key, 'delete', selectedFileKeys);
      setClickedActionInfoHandler(event, key);
    }
    else {
      selectedFileKeysHandler(key, 'add', selectedFileKeys);
      setClickedActionInfoHandler(event, key);
    }
  };

  const showActionModalHandler = (key, type) => {
    console.log(key, type);
    let targetEl;
    let targetRect;

    if (type === 'file') {
      targetEl = document.getElementById(`file-action-${key}`);
      targetRect = targetEl.getBoundingClientRect();
    }

    if (type === 'folder') {
      targetEl = document.getElementById(`folder-action-${key}`);
      targetRect = targetEl.getBoundingClientRect();
      
      }

      // const bottomSpace = window.innerHeight - targetRect.top;
      // console.log(bottomSpace);
      // const heightLimit = 300;
      const heightLimit = 600;

      console.log(targetRect.top, window.innerHeight - targetRect.top, window.innerHeight)
      
      if (window.innerHeight - targetRect.top > heightLimit) {
        setModalPositions({
          top: targetRect.top ,
          left: targetRect.left - 200,
          right: null,
          // right: targetRect.left,
          bottom: null,
          heightLimit: heightLimit,
        });
      } else {
        setModalPositions({
          top: null,
          left: targetRect.left - 200,
          right: null,
          bottom: window.innerHeight - targetRect.bottom,
          heightLimit: targetRect.bottom,
        });
      }



      if (targetRect.bottom < (window.innerHeight / 2)) {
        setModalPositions({
          top: targetRect.top ,
          left: targetRect.left - 200,
          right: null,
          // right: targetRect.left,
          bottom: null,
          heightLimit: heightLimit,
        });
      } else {
        setModalPositions({
          top: null,
          left: targetRect.left - 200,
          right: null,
          bottom: window.innerHeight - targetRect.bottom,
          heightLimit: heightLimit,
        });
      }

      console.log(targetEl);
      console.log(targetRect);
    };


    const selectAllObjectsHandler = () => {
      const addPrefixList = [];
      const addFileList = [];

      for (const object of topLevels) {
        if (object.Key.endsWith('/') && object.Key !== currentPrefix) {
          addPrefixList.push(object.Key);
        } 
        if (!object.Key.endsWith('/')) {
          addFileList.push(object.Key);
        }
      }

      dispatch('SET_MOVEPREFIXES', addPrefixList);
      dispatch('SET_SELECTED_FILEKEYS', addFileList);
    };

    const deSelectAllObjectsHandler = () => {
      dispatch('SET_MOVEPREFIXES', []);
      dispatch('SET_SELECTED_FILEKEYS', []);
    };










  let topLevelsBody;


  if (topLevels.length < 1) {
    topLevelsBody = (
      // <tbody>
      //   <tr>
      //     <td>no-items</td>
      //   </tr>
      // </tbody>
      <div>{t('objectList.03', 'no items')}</div>
    );
  }
  // if (topLevels.length === 1 && topLevels[0].Key.endsWith('/')) {
  //   topLevelsBody = (
  //     // <tbody>
  //     //   <tr>
  //     //     <td>no-items</td>
  //     //   </tr>
  //     // </tbody>
  //     <div>no-items</div>
  //   );
  // }

  // if ( (topLevels.length === 1 && !topLevels[0].Key.endsWith('/') ) || 
  //   topLevels.length >= 2
  // ) {
  if (topLevels.length >= 1) {

    let dispTopLevels = topLevels;

    if (!searchSelectedItem) {
      const sortedTopLevels = createSortedTopLevels(topLevels);
      dispTopLevels = sortedTopLevels;
    }

    dispTopLevels = bucketObjectUtils.createNoTrashTopLevels(dispTopLevels, allObjectsMetaList);

    topLevelsBody = (
      <tbody>
        {/* {topLevels.map((object, index) => { */}
        {dispTopLevels.map((object, index) => {

          const isInSelectedFileKeys = selectedFileKeys.find(key => {
            return key === object.Key;
          });

          const isInMovePrefixes = movePrefixes.find(px => {
            return px === object.Prefix;
          });
          // console.log(isInSelectedFileKeys);

          const fileActionElementId = `file-action-${object.Key}`;
          

          let folderActionElementId= `folder-action-${object.Key}`;
          
          // if (object.Prefix) {
          //   folderActionElementId= `folder-action-${object.Prefix}`;
          // }


          // console.log(object);
          if (object.Key && object.Key.endsWith('/')) {
            if (object.Key.substring(currentPrefix.length, object.Key.length -1) === '/') {
              return null;
            } else {

              const isInMovePrefixes = movePrefixes.find(px => {
                return px === object.Key;
              });

              let folderNameBody = (
                <span
                  onClick={() => {    
                    if (!isLoading) {
                      // getAllBucketObjects(bucketName, object.Key); 
                      createToplevelsHandler(object.Key, allBucketObjects);
                      storeObjectAccessHandler(object.Key);
                    }            
                  }}
                >
                  {marks.folderFillMark}{' '}
                  {decodeURI(object.Key.substring(currentPrefix.length, object.Key.length -1))}
              </span>
              );

              if (clickedActionInfo && clickedActionInfo.key === object.Key && clickedActionInfo.name === 'change-folder-name') {
                folderNameBody = (
                  <FolderNameChange 
                    setClickedActionInfoHandler={setClickedActionInfoHandler}
                    object={object}
                  />
                );
              }

              return (
                <tr key={index} >
                  <td>
                    <span                         
                      onClick={() => { 
                        if (!isLoading) {
                          folderClickSelectHandler(isInMovePrefixes, object.Key);
                        }
                      }}
                    >
                      {/* {isInMovePrefixes ? 'sele' : 'dese'} */}
                      {isInMovePrefixes ? 
                        <span>
                          {checkMark}
                        </span> 
                      : 
                        <span>
                          {noCheckMark}
                        </span>
                      }                      
                    </span>
                  </td>
                  <td>
                    <span className={classes.objectTableObjectName}>
                      {folderNameBody}
                    </span>
                  </td>

                  {(window.innerHeight >= 480 && window.innerWidth >= 480) && (
                    <td>
                    </td>
                  )}

                  <td>
                    <span id={folderActionElementId}
                      title={'select action for folder'}
                      onClick={() =>{ 
                        if (!isLoading) {
                          setClickedObjectKeyHandler(object.Key); 
                          showActionModalHandler(object.Key, 'folder');
                          setClickedActionInfoHandler(null); 
                        }
                      }}
                    >
                      {t('objectList.04', 'action')} {marks.triangleDown}
                    </span>
                    {clickedObjectKey === object.Key && (
                      <div>
                       <Backdrop 
                          zIndex={'90'}
                          // backgroundColor={'rgba(0, 0, 0, 0.1)'}
                          backgroundColor={'rgba(0, 0, 0, 0)'}
                          onCancel={() => { 
                            if (!isLoading) {
                              setClickedObjectKeyHandler(''); 
                              setClickedActionInfo(null);
                            }
                          }}
                        />
                        <ModalPosition 
                          top={modalPositions.top}
                          left={modalPositions.left}
                          right={modalPositions.right}
                          bottom={modalPositions.bottom}
                          heightLimit={modalPositions.heightLimit}
                          zIndex='100'
                          // onClose={() => {
                          //   setClickedObjectKeyHandler(''); 
                          //   setClickedActionInfo(null);
                          // }}
                        >
                          {/* <div style={{textAlign:'right'}}>
                            <span style={{cursor:'pointer'}} 
                              onClick={() => {
                                if (!isLoading) {
                                  setClickedObjectKeyHandler(''); 
                                  setClickedActionInfo(null);
                                } 
                              }}
                            >
                              X
                            </span>
                          </div> */}
                          <FolderActions 
                            deleteFolderHandler={deleteFolderHandler}
                            folderClickSelectHandler={folderClickSelectHandler}
                            setMovePrefixesHandler={setMovePrefixesHandler}
                            setClickedActionInfoHandler={setClickedActionInfoHandler}
                            setClickedObjectKeyHandler={setClickedObjectKeyHandler}
                            clickedActionInfo={clickedActionInfo}
                            object={object}
                            isInMovePrefixes={isInMovePrefixes}
                            isLoading={isLoading}
                          />
                        </ModalPosition>
                      </div>
                    )}
                  </td>
                  <td>
                    <span></span>
                  </td>
                </tr>
              );
            }
            }
            if (object.Key && !object.Key.endsWith('/')) {
              const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
              const timeEndIndex = fileName.indexOf('Z');
              const originalName = fileName.substring(timeEndIndex+2);
              
              const fileMark = createFileMark(object);
              // console.log(object.mimeType, fileType);

              let fileNameBody = (
                <span title={originalName}>
                  {fileMark}{' '}
                  {decodeURI(originalName)}
                </span>
              );

              if (clickedActionInfo && 
                clickedActionInfo.key === object.Key && 
                clickedActionInfo.name === 'change-file-name'
              ) {
                fileNameBody = (
                  <FileNameChange 
                    // setClickedObjectKeyHandler={setClickedActionInfo}
                    setClickedActionInfoHandler={setClickedActionInfoHandler}
                    object={object}
                  />
                );
              }

              return (
                <tr key={index}>
                  <td>
                    <span
                      onClick={(event) => {
                        if (!isLoading) {
                          fileClickSelectHandler(event, isInSelectedFileKeys, object.Key)
                        }
                      }}
                    >
                      {/* {isInSelectedFileKeys ? 'sele' : 'dese'} */}
                      {isInSelectedFileKeys ? 
                        <span>
                          {checkMark}
                        </span> 
                      : 
                        <span>
                          {noCheckMark}
                        </span>
                      } 
                    </span>
                  </td>
                  <td>
                    <span className={classes.objectTableObjectName}>
                      {fileNameBody}
                    </span>
                  </td>
                  {(window.innerHeight >= 480 && window.innerWidth >= 480) && (
                    <td>
                      {object.Size && (
                        <span>{numeral(object.Size).format('0.0 b')}</span>
                      )}
                    </td>
                  )}
                  <td>
                    <span id={fileActionElementId}
                      title={'select action for file'}
                      onClick={() =>{ 
                        if (!isLoading) {
                          setClickedObjectKeyHandler(object.Key); 
                          showActionModalHandler(object.Key, 'file'); 
                          setClickedActionInfoHandler(null);
                          storeObjectAccessHandler(object.Key);
                        }
                      }}
                    >
                      {t('objectList.04', 'action')} {marks.triangleDown}
                    </span>
                    {clickedObjectKey === object.Key && (
                      <div>
                        <Backdrop 
                          zIndex={'90'}
                          // backgroundColor={'rgba(0, 0, 0, 0.1)'}
                          backgroundColor={'rgba(0, 0, 0, 0)'}
                          onCancel={() => { 
                            if (!isLoading) {
                              setClickedObjectKeyHandler(''); 
                              setClickedActionInfo(null);
                            }
                          }}
                        />
                        <ModalPosition 
                          top={modalPositions.top}
                          left={modalPositions.left}
                          right={modalPositions.right}
                          bottom={modalPositions.bottom}
                          heightLimit={modalPositions.heightLimit}
                          zIndex='100'
                          // onClose={() => {
                          //   setClickedObjectKeyHandler(''); 
                          //   setClickedActionInfo(null);
                          // }}
                        >
                          {/* <div style={{textAlign:'right'}}>
                            <span style={{cursor:'pointer'}} 
                              onClick={() => {
                                if (!isLoading) {
                                  setClickedObjectKeyHandler(''); 
                                  setClickedActionInfo(null);
                                } 
                              }}
                            >
                              X
                            </span>
                          </div> */}
                          <FileActions 
                            setClickedActionInfoHandler={setClickedActionInfoHandler}
                            getObjectSignedUrlHandler={getObjectSignedUrlHandler}
                            // fileDownloadHandler={fileDownloadHandler}
                            deleteBucketObjectsHandler={deleteBucketObjectsHandler}
                            selectedFileKeysHandler={selectedFileKeysHandler}
                            setClickedObjectKeyHandler={setClickedObjectKeyHandler}
                            clickedActionInfo={clickedActionInfo}
                            objectSignedUrl={objectSignedUrl}
                            isInSelectedFileKeys={isInSelectedFileKeys}
                            object={object}
                            isLoading={isLoading}
                          />
                        </ModalPosition>
                      </div>
                    )}
                  </td>
                  {(window.innerHeight >= 480 && window.innerWidth >= 480) && (
                    <td>
                      <span>
                        {object.LastModified && (
                          // new Date(object.LastModified).toLocaleString()
                          moment(object.LastModified).format("YYYY-MM-DD h:mm a")
                        )}
                      </span>
                    </td>
                  )}
                </tr>
              );
            } 
      
      })}
      </tbody>
    );
  }




  return (
    <div className={classes.objectTableView}>
        {/* <span>abcd-efg,  &#10006; &#x2715; &#215;</span><span id="testid">bucket-object-list</span> */}
      {/* {isLoading && <div>loading...loading...</div>} */}
      {/* <ObjectControl /> */}
      {/* <div onClick={selectAllObjectsHandler}>add-all-test</div>
      <div onClick={deSelectAllObjectsHandler}>remove-all-test</div> */}

      <PrefixLine 
          getBucketCommonPrefixes={getBucketCommonPrefixes} 
          getAllBucketObjects={getAllBucketObjects}
          createToplevelsHandler={createToplevelsHandler}
          isLoading={isLoading}
      />

      {/* <div style={{display:"flex", justifyContent:"space-between"}}>
        <div></div>
        <SelectedObjectsControll />
      </div> */}

      <div className={classes.objectTableContainer}>

        <div className={classes.objectTableMenu}>
          <BucketObjectMenu />
        </div>

        <div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div></div>
            <SelectedObjectsControll />
          </div>

          <table className={classes.objectTable}>
            <thead>
              <tr>
                <th>
                  {isAllSelected && (
                  <span
                    onClick={() => {
                      if (!isLoading) {
                        deSelectAllObjectsHandler();
                      }
                    }}
                  >
                    {marks.checkMark}
                  </span>
                  )}
                  {!isAllSelected && (
                  <span
                    onClick={() => {
                      if (!isLoading) {
                        selectAllObjectsHandler();
                      }
                    }}
                  >
                    {marks.noCheckMark}
                    </span>
                  )}
                </th>
                <th>{t('objectList.01', 'file name')}</th>
                <th></th>
                {/* <th></th> */}
                {(window.innerHeight >= 480 && window.innerWidth >= 480) && (
                  <>
                    <th></th>
                    <th>{t('objectList.02', 'last updated')}</th>
                  </>
                )}
              </tr>
            </thead>
            {topLevelsBody}
          </table>
        </div>

      </div>

    </div>
  );
}

export default BucketObjectList;
