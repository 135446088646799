import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import _ from 'lodash';

import BucketObjectMenu from '../BucketObject/BucketObjectMenu';

import { useStore } from '../../../hook-store/store';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
// import { 
//   updateObjectMetadata, 
//   getObjectMetadata,
//   getMetadataFromMetaList,
//  } from '../../../utils/bucket/object-metadata-util';

import { BACKEND_URL } from '../../../App';

import { marks } from '../../../utils/marks';

import classes from '../BucketObject/BucketObjectList.module.css';
// import { update } from 'lodash';

// import ObjectControl from '../ObjectControl/ObjectControl';

function TrashPrefixLine(props) {

  const {
    selectedFolder,
    trashObjects,
    selectObjectHandler,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const { 
    // selectedFileKeys,
    // movePrefixes,
    // allBucketObjects,
    // bucketName,
  } =store.bucketObjectStore;
  const { 
    // allObjectsMetaList,
  } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;
  // const windowValues = store.uiStore.windowValues;

  // const [trashObjects, setTrashObjects] = useState([]);
  // const [selectedTrashObjects, setSelectedTrashObjects] = useState([]);
  // const [selectedFolder, setSelectedFolder] = useState();
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  let trashPrefixLineBody;

  if (selectedFolder) {
    const splitArray = selectedFolder.Key.split('/');

    const topLevelName = `${t('trash.10', 'top')}`;

    if (splitArray.length > 0) {
    
      trashPrefixLineBody = (
        <div className={classes.prefixLine}>
          <span onClick={() => { 
              if (!isLoading) {
                // getBucketCommonPrefixes(bucketName); 
                // createToplevelsHandler('', allBucketObjects);
                selectObjectHandler('', trashObjects);
              }
            }}
          >
            {marks.folderFillMark} {topLevelName} {' / '}
          </span>
          {' '}
          {splitArray.map((element, index) => {
            let prefix = splitArray[0] + '/';
            
            for (let i=0; i<index; i++) {
              if (element) {
                prefix = prefix + splitArray[i+1] + '/';
              }
            }
            // console.log(index, 'prefix: ', prefix, 'element: ', element);
            if (element) {
              // console.log(prefix, currentPrefix)
              let displayName = element;

              if (index === 0 && element.startsWith('trash-')) {
                const trashYIndex = element.indexOf('Y');

                displayName = element.substring(trashYIndex+2);
              }
              return (
                <span key={index} 
                  style={index === splitArray.length -2 ? {fontWeight:"bold"} : null}
                  onClick={() => { 
                  if (!isLoading && index < splitArray.length -2) {
                    // getAllBucketObjects(bucketName, prefix); 
                    // createToplevelsHandler(prefix, allBucketObjects);
                    selectObjectHandler(prefix, trashObjects)
                  }
                  }}
                >
                  {/* {marks.folderFillMark} {displayName}{index < splitArray.length -2 && ' / '} */}
                  {marks.folderFillMark} {decodeURI(displayName)}{index < splitArray.length -2 && ' / '}
                </span>
              );
            } else {
              return null;
            }
        })}
        
        </div>
      );
    }
  }



  return (
      <Fragment>
        <div>{trashPrefixLineBody}</div>
      </Fragment>
  );
}

export default TrashPrefixLine;
