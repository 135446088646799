import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

// import EditLinkImageStylizerIpaImages from "./EditLinkImageStylizerIpaImages";
import EditLinkAudioMixerAddFile from "./EditLinkAudioMixerAddFile";
import FolderAndFile from "../../FolderAndFile/FolderAndFile";

import { useStore } from "../../../../hook-store/store";
import { 
  isAudioMimeType,
  getOriginalFileNameFromKey,
 } from "../../../../utils/bucket/bucket-object-util";
// import { isForImageStylizerFile } from "../../../../utils/bucket/image-file-edit-util";

import { textAudioToolsLink } from "../../../../App";

import { marks } from "../../../../utils/marks";
import classes from "./EditLinkModal.module.css";

function EditLinkAudioMixer(props) {
  const {
    smallImageUrl,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { 
    editLinkModalData,
    imageUrlList,
    allBucketObjects,
   } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  
  const [ipaImageKeys, setIpaImageKeys] = useState([]);
  const [paramData, setParamData] = useState({
    files: [
      { key: editLinkModalData.key, mimeType: editLinkModalData.mimeType }
    ]
  });
  const [showFileSelect, setShowFileSelect] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);

  // console.log('paramData', paramData);
  // console.log('paramData ipaImageKeys', ipaImageKeys);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);

  const addIpaImageKeysHandler = (file) => {
    // console.log(file);
    if (ipaImageKeys.length >= 1) {
      return;
    }

    if (!file.mimeType || !isAudioMimeType(file.mimeType)) {
      return;
    }

    const ipaKeys = ipaImageKeys;

    // const imageUrlObj = imageUrlList.find(element => {
    //   return element.key === file.Key;
    // });

    const addedKeys = ipaKeys.concat({
      id: Math.random(),
      key: file.Key,
      // imageUrlObj: imageUrlObj,
      mimeType: file.mimeType,
    });

    const paramIpaKeys = addedKeys.map(ele => {
      return {
        id: ele.id,
        key: ele.key,
        mimeType: ele.mimeType,
      }
    });

    setIpaImageKeys(addedKeys);
    // setParamData({
    //   file: {
    //     key: editLinkModalData.key,
    //     mimeType: editLinkModalData.mimeType,
    //   },
    //   ipaImages: paramIpaKeys
    // })
    setParamData({
      files: [
        { key: editLinkModalData.key, mimeType: editLinkModalData.mimeType }
      ].concat(paramIpaKeys)
    })

    dispatch('SHOW_NOTIFICATION', {
      status: '',
      title: 'file added',
      message: '',
    });

    setTimeout(() => {
      dispatch('CLEAR_NOTIFICATION');
    },1000 * 2);

  };


  const targetUrl = `${textAudioToolsLink}/audio-editor?keyData=${JSON.stringify(paramData)}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=${editLinkModalData.openApp}`
  
  let appDispName = 'Audio Combiner';

  if (editLinkModalData.openApp === 'audioMixer') {
    appDispName = 'Audio Mixer';
  }
  let editLinkImageStylizerBody;

  editLinkImageStylizerBody = (
    <div>
      <div className={classes.editLinkSection}>
        <div className={classes.editLinkTitle}>
          open files in {appDispName}
        </div>
      </div>

      <div className={classes.editLinkSection}>
        <div>
          selected file
        </div>
          <strong>
            {/* {getOriginalFileNameFromKey(editLinkModalData.key)} */}
            {decodeURI(getOriginalFileNameFromKey(editLinkModalData.key))}
          </strong>
      </div>

      {ipaImageKeys.length === 0 && (
        <div className={classes.editLinkSection}>
          <a 
            href={targetUrl}
            target="_blank" rel="noreferrer noopener"
            // style={}
          >
            <button className="btnBase">
              open file in {appDispName}
            </button>
          </a>
        </div>
      )}

      <div className={classes.editLinkSection}>
        <div>
          <span
            onClick={() => { 
              setShowFileSelect(true);
            }}
          >
            select another audio file {marks.triangleDown}
          </span>
        </div>
        </div>
        {showFileSelect && (
          <div>
            <div>
              <EditLinkAudioMixerAddFile 
                ipaImageKeys={ipaImageKeys}
                setIpaImageKeys={setIpaImageKeys}
                paramData={paramData}
                setParamData={setParamData}
                targetUrl={targetUrl}
                appDispName={appDispName}
              />
            </div>
    
            <div>
              <strong>
                select audio file
              </strong>
            </div>
            <FolderAndFile 
              onFileClick={addIpaImageKeysHandler}
              withImageLink={true}
              withAllLink={false}
              withCheckbox={false}
              getUploadSelectedHandler={() => {}}
              // filterMimeTypeList={['image/png', 'image/jpeg']}
            />
          </div>
        )}
    </div>
  );

  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {editLinkImageStylizerBody}
    </Fragment>
  );
}

export default EditLinkAudioMixer;
