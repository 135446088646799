import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
// import { saveAs } from 'file-saver';

import Backdrop from '../../../Backdrop/Backdrop';
import ModalConfirm from '../../../Modal/ModalConfirm';
import TrashExistKeysList from '../TrashSelectedControl/TrashExistKeysList';
// import ToolsLink from './ToolsLink';

import { useStore } from '../../../../hook-store/store';

import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
import { createFileMark } from '../../../../utils/bucket/bucket-ui-util';

// import { BACKEND_URL } from '../../../../App';

import { marks } from '../../../../utils/marks';

// import classes from './FileActions.module.css';
import classes from './TrashActions.module.css';



// import { updateTrashMetadata, getObjectMetadata } from '../../../../utils/bucket/object-metadata-util';




function TrashActions(props) {

  const {
    // getObjectSignedUrlHandler,
    // fileDownloadHandler,
    // deleteBucketObjectsHandler,
    // selectedFileKeysHandler,
    // setClickedObjectKeyHandler,
    // objectSignedUrl,
    // isInSelectedFileKeys,
    setClickedActionInfoHandler,
    clickedActionInfo,
    object,
    trashObjects,
    deleteTrashboxMetaHandler,
    deleteFilesFoldersHandler,
    selectedFolder,
    showCopyFolders,
    setShowCopyFolders,
    // selectedTrashObjects,
    // isLoading,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    bucketName, 
    // currentPrefix,
    allBucketObjects,
  } = store.bucketObjectStore;
  const { gLoading } = store.uiStore;

  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;

  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [existDestKeys, setExistDestKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const getExistDestKeys = (objects, allBucketObjects, action) => {

    const eDestKeys = [];

    for (const object of objects) {
      const trashYIndex = object.Key.indexOf('Y');
      const destKey = object.Key.substring(trashYIndex + 2);
      
      const isDestKeyExist = allBucketObjects.find(element => {
        return element.Key === destKey;
      });
  
      if (isDestKeyExist) {
        eDestKeys.push(destKey);
      }
    }

    const uniqList = _.uniq(eDestKeys);
    setExistDestKeys(uniqList);

    console.log(uniqList);
    return uniqList;

  

  };
  // console.log(clickedActionInfo)


  let actionConfirmModalBody;

  if (clickedActionInfo && clickedActionInfo.name === 'restore-object') {
    actionConfirmModalBody = (
      <div>
        <Backdrop 
          zIndex={'110'}
          // backgroundColor={'rgba(0, 0, 0, 0.1)'}
          onCancel={() => { 
            if (!isLoading) {
              setClickedActionInfoHandler(null);
            }
          }}
        />  
        <ModalConfirm 
          hideButtons={existDestKeys.length > 0 ? true : false}
          onClose={() => { setClickedActionInfoHandler(null); }}
          onAccept={() => { 
            if (!isLoading) {
              deleteTrashboxMetaHandler(
                [object], 
                allObjectsMetaList,
              ); 
            }
          }}
          isLoading={isLoading}
        >
          {existDestKeys.length > 0 && (
            <div>
              <TrashExistKeysList 
                existDestKeys={existDestKeys}
              />
            </div>
          )}

          {existDestKeys.length === 0 && (
            <div>
              {t('trash.06', 'restore from trash')} ?
            </div>
          )}
          </ModalConfirm>
      </div>
    );
  }

  if (clickedActionInfo && clickedActionInfo.name === 'delete-object') {
    actionConfirmModalBody = (
      <div>
        <Backdrop 
        zIndex={'110'}
        // backgroundColor={'rgba(0, 0, 0, 0.1)'}
        onCancel={() => { 
          if (!isLoading) {
            setClickedActionInfoHandler(null);
          }
        }}
      />  
        <ModalConfirm 
          hideButtons={false}
          onClose={() => { setClickedActionInfoHandler(null); }}
          onAccept={() => { 
            if (!isLoading) {
              deleteFilesFoldersHandler(
                bucketName,
                [object],
                trashObjects,
              );
            }
          }}
          isLoading={isLoading}
        >
          <div>
          {t('trash.07', 'delete')} ?
          </div>
        </ModalConfirm>
      </div>
      );
  }

  let displaySize; 
  let originalName = object.Key.split('/')[object.Key.split('/').length - 2];

  if (object.Key.split('/').length === 2 && object.Key.startsWith('trash-')) {
    const folderName = object.Key.split('/')[0];
    const trashYIndex = object.Key.indexOf('Y');
    originalName = folderName.substring(trashYIndex+2);
  }

  if (!object.Key.endsWith('/')) {
    const fileName = object.Key.split('/')[object.Key.split('/').length - 1];
    const timeEndIndex = fileName.indexOf('Z');
    originalName = fileName.substring(timeEndIndex+2);
    displaySize = `(${numeral(object.Size).format('0.0 b')})`;
  }
  // const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
  // console.log(timeEndIndex);



  const objectPrefix = bucketObjectUtils.getPrefixFromKey(object.Key);
        
  const trashYIndex = objectPrefix.indexOf('Y');
  const noTrashPreName = objectPrefix.substring(trashYIndex + 2);

  let displayOriPlace = noTrashPreName;

  if (object.Key.endsWith('/')) {
    // const trashYIndex = objectPrefix.indexOf('Y');
    // const noTrashPreName = objectPrefix.substring(trashYIndex + 2);
    const separateList = noTrashPreName.split('/');
    // console.log(noTrashPreName, separateList);
    separateList.pop();
    separateList.pop();
    // console.log(separateList);
    if (separateList.length >= 1) {
      displayOriPlace = separateList.join('/') + '/';
    } else {
      displayOriPlace = '/'
      // displayOriPlace = ''
    }
  }

  if (!objectPrefix) {
    displayOriPlace = '/'
    // displayOriPlace = ''
  }

  let fileActionsBody;

  fileActionsBody = (
    <div className={classes.objectActionModal}
    // style={{display:"flex", flexDirection:"column"}}
    >
      
      {/* {deleteModalBody} */}

      {object.Key.endsWith('/') && (
        <button className={classes.objectActionListItem}
        name="foldername"
        onClick={(event) => { 
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        {/* {originalName} */}
        {decodeURI(originalName)}
      </button>
      )}

      {!object.Key.endsWith('/') && (
        <button className={classes.objectActionListItem}
          name="filename"
          onClick={(event) => { 
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
            }
          }}
        >
          {/* {originalName} */}
          {decodeURI(originalName)}
          {' '}
          {displaySize}
        </button>
      )}

      <button className={classes.objectActionListItem}
        name="trash-move-time"
        onClick={(event) => { 
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      > 
        {t('trash.03', 'time')}
        <br/>
        {object.parsedMetadata && (
          moment(object.parsedMetadata.trashMoveTime).format("YYYY-MM-DD h:mm a")
        )}
      </button>

      <button className={classes.objectActionListItem}
        name="originalplace"
        onClick={(event) => { 
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      > 
        {t('trash.05', 'original place')}
        <br/>
        {/* {displayOriPlace} */}
        {decodeURI(displayOriPlace)}
      </button>
      

      <button className={classes.objectActionListItem}
        disabled={isLoading}
        // name="change-file-name"
        name="restore-object"
        onClick={(event) => {
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
            // setClickedObjectKeyHandler('');
            // deleteTrashboxMetaHandler([object], allObjectsMetaList);
            getExistDestKeys([object], allBucketObjects);
          }
        }}
      >
        {marks.moveMark} {t('trash.06', 'restore from trash')}
      </button>

      {/* <button className={classes.objectActionListItem}
        name="copy-object"
        disabled={isLoading}
        onClick={(event) => {
          if (!isLoading) {
            setShowCopyFolders(true);
          }
        }}
      >
        copy-from-trash
      </button> */}

      <button className={classes.objectActionListItem}
        name="delete-object"
        disabled={isLoading}
        onClick={(event) => {
          if (!isLoading) {
            // deleteBucketObjectsHandler(bucketName, [object.Key]);
            setClickedActionInfoHandler(event, object.Key);
            // setClickedObjectKeyHandler('');
            getExistDestKeys([object], allBucketObjects);
            // setShowDeleteModal(true);
          }
        }}
      >
        {marks.trashMark} {t('trash.07', 'delete')}
      </button>


      {/* <button name="get-url"
        disabled={isLoading}
        onClick={(event) => { 
          if (!isLoading) {
            getObjectSignedUrlHandler(bucketName, object.Key, 3600);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        get-signed-url
      </button>
      {objectSignedUrl && clickedActionInfo.key === object.Key && (
        <div>
          url-for-download (valid for 1 hour): <a href={objectSignedUrl} target='_blank' rel="noreferrer noopener">
            {objectSignedUrl}
            </a>
        </div>
      )} */}

    {/* <button className={classes.objectActionListItem}
      name="download"
      disabled={isLoading}
      onClick={(event) => { 
        if (!isLoading) {
          fileDownloadHandler(bucketName, object.Key, 3600); 
          setClickedActionInfoHandler(event, object.Key);
        }
      }}
    >
      {marks.downloadMark} download file
    </button> */}
    {/* <FileDownload 
      setClickedActionInfoHandler={setClickedActionInfoHandler}
      object={object}
    /> */}


    {/* {!isInSelectedFileKeys && (
      <button name="select-object" 
        disabled={isLoading}
        onClick={(event) => {
          if (!isLoading) {
            selectedFileKeysHandler(object.Key, 'add', selectedFileKeys);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        select-add-object
      </button>
    )}
    {isInSelectedFileKeys && (
      <button name="deselect-object"
        disabled={isLoading}
        onClick={(event) => {
          if (!isLoading) {
            selectedFileKeysHandler(object.Key, 'delete', selectedFileKeys);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        select-delete-object
      </button>
    )} */}





    {/* <button
      onClick={() => {
        bucketObjectUtils.getObjectFile(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucketName,
          object.Key,
          object.mimeType,
        ).then(resData => {
          return resData.text();
          // console.log(resData);
        }).then(text => {
          console.log(text);

          if (text.startsWith('<')) {
            // return HTMLtoDOCX(text);
            // const converted = htmlDocx.asBlob(text);
            // saveAs(converted, 'test-convert.docx');
          }
        }).then(converted => {
          console.log(converted);
        })
      }}
    >
      get-object-file
    </button> */}
  </div>
  )


  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {actionConfirmModalBody}
      {fileActionsBody}
    </Fragment>
  );
}

export default TrashActions;
