import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

import { useStore } from '../../../../../hook-store/store';

import * as bucketObjectUtils from '../../../../../utils/bucket/bucket-object-util';
import * as objectDownloadUtils from '../../../../../utils/bucket/object-download-util';
import { getShareObjectSignedUrl } from '../../../../../utils/share-bucket/share-bucket-object-util';
import { getShareObjectFile } from '../../../../../utils/share-bucket/share-bucket-object-util';
import { getShareDocxFile } from '../../../../../utils/share-bucket/share-object-download-util';
import { mimeTypes } from '../../../../../utils/file/mime-type';


import { BACKEND_URL } from '../../../../../App';

import { marks } from '../../../../../utils/marks';
import classes from './ShareFileActions.module.css';



function ShareFileDownload(props) {

  const {
    setClickedActionInfoHandler,
    object,
    shareOwnerInfo
    // isLoading,
  } = props;

  // console.log('ShareFileDownload.js-props', props);

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  const { bucketName} = store.bucketObjectStore;

  const { gLoading } = store.uiStore;

  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);




  const fileDownloadHandler = async (bucket, key, expires, object) => {
    console.log(bucket, key, expires, object)
    try {
      dispatch('SET_GLOADING', true);

      if (object.mimeType === mimeTypes.docx) {
        const isEditedDocx = await checkEditDocxFile();
        // console.log(isEditedDocx);
  
        if (isEditedDocx) {
          downloadEditDocx(object);
          
          return;
        }
      }

      if (object.mimeType === mimeTypes.odt) {
        const isEditedDocx = await checkEditDocxFile();
        // console.log(isEditedDocx);
  
        if (isEditedDocx) {
          downloadEditDocx(object);
          
          return;
        }
      }
  
      const resData = await getShareObjectSignedUrl(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        key,
        expires,
      );
  
      console.log(resData);
      
      const dataUrl = resData.data.url;
      
      bucketObjectUtils.openInNewTab(dataUrl);
  
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);

      // dispatch('SHOW_NOTIFICATION', {
      //   status: 'error',
      //   title: 'file download failed',
      //   message: '',
      // });

      // setTimeout(() => {
      //   dispatch('CLEAR_NOTIFICATION');
      // },1000 * 10);
    }

  };


  const checkEditDocxFile = async () => {
    try {
      dispatch('SET_GLOADING', true);
      const resData = getShareObjectFile(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucketName,
        object.Key,
        object.mimeType,
      );

      const convertText = await (await resData).text();

      if (convertText.startsWith('<')) {
        return true;
      } 

      dispatch('SET_GLOADING', false);

      return false;

    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
      throw err;
    }
  };

  const downloadEditDocx = async (object) => {
    try {
      dispatch('SET_GLOADING', true);

      const resData = await getShareDocxFile(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucketName,
        object.Key,
        object.mimeType,
      );
  
        console.log(resData.data)
      // const file = new File([resData], "download.docx");
  
      // saveAs(resData.data.data, "download.docx");
      const data = Uint8Array.from(resData.data.data);
      const content = new Blob([data.buffer], { type: mimeTypes.docx });

      const fileName = object.Key.split('/')[object.Key.split('/').length - 1];
      const fileNameSplit = fileName.split('.');
      const ext = fileNameSplit.pop();

      if (ext.toLowerCase() === 'odt') {
        const odtConvertName = fileNameSplit.join('.') + '.docx';
        saveAs(content, odtConvertName);
      }

      if (ext.toLowerCase() === 'docx') {
        saveAs(content, fileName);
      }

      dispatch('SET_GLOADING', false);

      // const encodedUri = window.URL.createObjectURL(content);
      // const link = document.createElement("a");
  
      // link.setAttribute("href", encodedUri);
      // link.setAttribute("download", "download-docx.docx");
  
      // link.click();
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
      throw err;
    }

  }


  let fileDownloadBody;

  if (object.mimeType !== mimeTypes.docx && object.mimeType !== mimeTypes.xlsx) {
    fileDownloadBody = (
      <button className={classes.objectActionListItem}
      name="download"
      disabled={isLoading}
      onClick={(event) => { 
        if (!isLoading) {
          fileDownloadHandler(shareOwnerInfo.userId, object.Key, 3600, object); 
          setClickedActionInfoHandler(event, object.Key);
        }
      }}
    >
      {marks.downloadMark} {t('fileAction.03', 'download file')}
    </button>
    )
  }


  return (
    <Fragment>
      {fileDownloadBody}
    </Fragment>
  );
}

export default ShareFileDownload;

