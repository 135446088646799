import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


import { useStore } from '../../../../hook-store/store';
import { 
  copyBucketObjects, 
  copyFolders,
} from '../../../../utils/bucket/bucket-object-util';

import { createFileMark } from '../../../../utils/bucket/bucket-ui-util';

import { BACKEND_URL } from '../../../../App';

import { marks } from '../../../../utils/marks';

// import classes from '../../BucketObject/BucketObjectList.module.css';

// import { update } from 'lodash';

// import ObjectControl from '../ObjectControl/ObjectControl';

function TrashboxItemCopy(props) {

  const {
    trashObjects,
    // selectedFolder,
    // selectedTrashObjects,
    // selectObjectHandler,
    // editSelectedTrashObjects,
    // deleteTrashboxMetaHandler,
    // deleteFilesFoldersHandler,
    clickedObjectKey,
    showCopyFolders,
    setShowCopyFolders,
    createRestoreObjectList,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const { 
    selectedFileKeys,
    movePrefixes,
    // topLevels,
    // accessList,
    allBucketObjects,
    bucketName,
  } =store.bucketObjectStore;

  // const { 
  //   allObjectsMetaList,
  // } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;
  // const windowValues = store.uiStore.windowValues;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const copyFromTrashboxHandler = async (bucket, objects, trashObjects, targetPrefix) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const restoreObjects = createRestoreObjectList(objects, trashObjects);
      // console.log(restoreObjects);

      const prefixes = [];
      const keys = [];

      for (const restoreObject of restoreObjects) {
        if (restoreObject.Key.endsWith('/')) {
          prefixes.push(restoreObject.Key);
        } else {
          keys.push(restoreObject.Key);
        }
      }
      // console.log(prefixes, keys)
      // throw new Error('error-error');

      let resultsForAction = {
        fileCopy: null,
        folderCopy: null,
      };

      // let combinedList = [];

      let addKeys = [];

      console.log(prefixes, keys);

      if (prefixes.length > 0) {
        const result = await copyFolders(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          prefixes,
          targetPrefix,
        );
  
        console.log(result);

        resultsForAction.folderCopy = result;

        addKeys = addKeys.concat(result.dataForMetadata.addKeys);

      }

      if (keys.length > 0) {
        const result = await copyBucketObjects(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          keys,
          targetPrefix,
        );
        
        console.log(result);
        
        resultsForAction.fileCopy = result;
        
        addKeys = addKeys.concat(result.dataForMetadata.addKeys);

      }

      dispatch('SET_OBJECTACTION', {
        actionType: 'copy-selected-objects',
        delete: false,
        add: true,
        result: resultsForAction,
      });

      console.log('addKeys', addKeys);

      const dataForMetadata = {
        addKeys: addKeys,
      };

      dispatch('SET_DATAFORMETADATA', dataForMetadata);


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'copy success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);



      setIsLoading(false);
      dispatch('SET_GLOADING', false);

      // dispatch('SET_SHOWFOLDERSMODAL', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `copy failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);


      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  return (
    <Fragment>
      <div>
        trashbox-item-copy-content
        clicked-object-key: {clickedObjectKey}

        <button
          onClick={() => {
            if (!isLoading) {
              const cObject = trashObjects.find(object => {
                return object.Key === clickedObjectKey;
              });

              copyFromTrashboxHandler(
                bucketName,
                [cObject],
                trashObjects,
                '',
              )
            }
          }}
        >
          copy-from-trash
        </button>
      </div>
    </Fragment>
  );
}

export default TrashboxItemCopy;
