import { createSortedTopLevels } from "./bucket-ui-util";
import { getMetadataFromMetaList } from './object-metadata-util';

export const getAllBucketObjects = async (url, token, bucket, prefix) => {
  try {

    const result = await fetch(url + `/bucket/all-objects?bucket=${bucket}&prefix=${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};


export const getAllBucketPrefixes = async (url, token, bucket, prefix) => {
  try {

    const result = await fetch(url + `/bucket/all-prefixes?bucket=${bucket}&prefix=${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const getBucketCommonPrefixes = async (url, token, bucket) => {
  try {
    const result = await fetch(url + `/bucket/common-prefixes?bucket=${bucket}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    // console.log(result);
    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const getBucketFolderContents = async (url, token, bucket, prefix) => {
  try {
    const result = await fetch(url + `/bucket/folder-top-level-contents?bucket=${bucket}&prefix=${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    // console.log(result);
    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const getObjectSignedUrl = async (url, token, bucket, key, expires) => {
  try {
    //// expires in second 1hour => 3600
    const result = await fetch(url + `/bucket/signed-url?bucket=${bucket}&key=${key}&expires=${expires}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const deleteBucketObjects = async (url, token, bucket, keys) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/bucket/delete-objects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        keys: keys,
        bucket: bucket,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const copyBucketObjects = async (url, token, bucket, inputKeys, targetPrefix) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/bucket/copy-objects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        inputKeys: inputKeys,
        targetPrefix: targetPrefix,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const moveDeleteBucketObjects = async (
  url, 
  token, 
  bucket, 
  inputKeys, 
  targetPrefix,
  action,
) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/bucket/move-delete-objects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        inputKeys: inputKeys,
        targetPrefix: targetPrefix,
        action: action,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};


export const createFolder = async (
  url, 
  token, 
  key, 
  bucket,
) => {
  try {
    const result = await fetch(url + `/bucket/create-folder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        // key: currentPrefix + folderName + '/',
        key: key,
        bucket: bucket,
      })
    });

    
    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const deleteFolders = async (url, token, bucket, prefixes) => {
  try {
    const result = await fetch(url + `/bucket/delete-folders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        keys: prefixes,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const copyFolders = async (url, token, bucket, inputKeys, targetKey) => {
  try {
    const result = await fetch(url + `/bucket/copy-folders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        inputKeys: inputKeys,
        targetKey: targetKey,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }
  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const moveDeleteFolders = async (
  url, 
  token, 
  bucket, 
  inputKeys, 
  targetKey,
  action,
) => {
  try {
    const result = await fetch(url + `/bucket/move-delete-folders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        inputKeys: inputKeys,
        targetKey: targetKey,
        action: action,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }
  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const changeFilename = async (url, token, bucket, oldKey, newKey, objectList) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/bucket/change-filename`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        oldKey: oldKey,
        newKey: newKey,
        objectList: objectList,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export function openInNewTab(url) {
  window.open(url, '_blank', 'noopener','noreferrer').focus();
}

export function getPrefixFromKey(key) {
  const objectArray = key.split('/');

  let prefix = '';

  if (objectArray.length >= 2) {
    objectArray.pop();
    prefix = objectArray.join('/') + '/';
  }

  return prefix;
}

export function getOriginalFileNameFromKey(key) {
  const fileName = key.split('/')[key.split('/').length - 1]
  const timeEndIndex = fileName.indexOf('Z');
  // console.log(timeEndIndex);
  const originalName = fileName.substring(timeEndIndex+2);

  return originalName;
}

export function getFolderNameFromKey(key) {
  const folderName = key.split('/')[key.split('/').length -2];
  // const timeEndIndex = folderName.indexOf('Z');
  // const originalName = folderName.substring(timeEndIndex+2);
  return folderName;
}


export function createSearchedTopLevels(searchSelectedItem, topLevels) {
  if (searchSelectedItem.Key.endsWith('/')) {
    return null;
  }
  
  const withoutSearh = topLevels.filter(object => {
    return object.Key !== searchSelectedItem.Key
  });

  const searchTopList = [searchSelectedItem].concat(withoutSearh);

  return searchTopList;
}


export const createPrefixTopLevelsFromAllList =  (prefix, allBucketObjects) => {
    const prefixTopLevels = [];

    for (const object of allBucketObjects) {
      if (prefix && object.Key.startsWith(prefix)) {
        if (object.Key.split(prefix).length === 2) {
          prefixTopLevels.push(object);
        }

        if (object.Key.split(prefix).length === 3 && object.Key.endsWith('/')) {
          prefixTopLevels.push(object);
        }
      }

      if (!prefix) {
        if (object.Key.split('/').length === 1) {
          prefixTopLevels.push(object);
        }

        if (object.Key.split('/').length === 2 && object.Key.endsWith('/')) {
          prefixTopLevels.push(object);
        }
      }
    }

    const sortedTopLevels = createSortedTopLevels(prefixTopLevels);

    return sortedTopLevels;
};

export const getFolderObjectsFromAllList = (prefix, allBucketObjects) => {
  const folderObjects = [];

  for (const object of allBucketObjects) {
    if (prefix && object.Key.startsWith(prefix)) {
      folderObjects.push(object);
    }

    if (!prefix) {
      folderObjects.push(object);
    }
  }

  return folderObjects;
};



export function createNoTrashTopLevels(topLevels, allObjectsMetaList) {
  const noTrashList = [];

  for (const object of topLevels) {
    // console.log('noTrashList objectKey', object.Key);
    let hasTrashMeta = false;

    const metaData = getMetadataFromMetaList(object.Key, allObjectsMetaList);

    if (metaData && metaData.parsedMetadata.location === 'trashbox') {
      hasTrashMeta = true;
    }

    if (!hasTrashMeta) {
      noTrashList.push(object);
    }
  }

  // console.log('noTrash metalength', allObjectsMetaList.length);
  // console.log('noTrashList', noTrashList);
  return noTrashList;
}


export const getObjectFile = async (url, token, bucket, key, mimeType) => {
  try {
    const result = await fetch(url + `/bucket/object-file?bucket=${bucket}&key=${key}&mimeType=${mimeType}`, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    console.log(result);

    const resData = await result.blob();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    // setIsLoading(false);
    throw err;
  }
};


export function isImageMimeType(mimeType) {
  if (mimeType && mimeType.split('/')[0] === 'image') {
    return true;
  }

  return false;
}

export function isAudioMimeType(mimeType) {
  if (mimeType && mimeType.split('/')[0] === 'audio') {
    return true;
  }

  return false;
}

export function isVideoMimeType(mimeType) {
  if (mimeType && mimeType.split('/')[0] === 'video') {
    return true;
  }

  return false;
}