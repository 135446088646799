import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
// import { saveAs } from 'file-saver';

import FileSharingUsers from './FileSharingUsers';

import { useStore } from '../../../../../hook-store/store';
import { 
  getMetadataFromMetaList,
 } from '../../../../../utils/bucket/object-metadata-util';
import { 
  updateShareMetadata,
  // getSharingUsers,
} from '../../../../../utils/share-bucket/share-bucket-util';

import { BACKEND_URL } from '../../../../../App';

import { marks } from '../../../../../utils/marks';

import classes from './FileShareModalContent.module.css';

// import { getOriginalFileNameFromKey } from '../../../../../utils/bucket/bucket-object-util';

function FileShareModalContent(props) {

  const {
    setClickedActionInfoHandler,
    object,
    // isLoading,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    bucketName, 
    currentPrefix,
  } = store.bucketObjectStore;
  
  const { allObjectsMetaList } = store.objectMetadataStore;
  const { sharingUsers } = store.shareObjectStore;
  const { gLoading } = store.uiStore;

  // const [shareEmailInput, setShareEmailInput] = useState('');
  const [shareInputs, setShareInputs] = useState({
    email: { value: '', isValid: true },
    type: { value: '', isValid: true },
    action: { value: '', isValid: true },
  });

  const [isValidInputs, setIsValidInputs] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // console.log(shareInputs)

  let shareInfoMeta;
  const objectMeta = getMetadataFromMetaList(object.Key, allObjectsMetaList);

  if (objectMeta && objectMeta.parsedMetadata) {
    shareInfoMeta = objectMeta.parsedMetadata.shareInfo;
  }

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    setShowConfirm(false);

    const isValid = validateShareInputs(shareInputs, shareInfoMeta);
    setIsValidInputs(isValid);

  },[shareInputs]);

  // const setShareEmailHandler = (event) => {
  //   setShareEmailInput(event.target.value);
  // }

  const setShareInputsHandler = (inputIdentifier, enteredValue) => {
    setShareInputs((curInputs) => {
      return {
        ...curInputs,
        [inputIdentifier]: { value: enteredValue, isValid: true },
      }
    })
  }

  const validateShareInputs = (inputs, shareInfo) => {

    const type = inputs.type.value;
    const action = inputs.action.value;
    const email = inputs.email.value;

    if (!email || !type || !action) {
      return false;
    }

    if (!inputs.email.value.includes('@')) {
      return false;
    }

    const emailUser = sharingUsers.find(user => {
      return user.email === email;
    });


    if (shareInfo) {
      if (type === 'read') {
        if (action === 'add') {
          const isInList = shareInfo.readIds.find(element => {
            if (emailUser) {
              return element === emailUser.userId;
            } else {
              return null;
            }
          });
          
          if (isInList) {
            // console.log('read-add-isInList')
            return false;
          }
        }
    
        if (action === 'delete') {
          const isInList = shareInfo.readIds.find(element => {
            if (emailUser) {
              return element === emailUser.userId;
            } else {
              return null;
            }
          });
          
          if (!isInList) {
            // console.log('read-delete-!isInList')

            return false;
          }
        }
      } 



      if (type === 'write') {
        if (action === 'add') {
          const isInList = shareInfo.writeIds.find(element => {
            if (emailUser) {
              return element === emailUser.userId;
            } else {
              return null;
            }
          });
          
          if (isInList) {
            // console.log('write-add-isInList')
            return false;
          }
    
        }
    
        if (action === 'delete') {
          const isInList = shareInfo.writeIds.find(element => {
            if (emailUser) {
              return element === emailUser.userId;
            } else {
              return null;
            }
          });
          
          if (!isInList) {
            // console.log('write-delete-!isInList')
            return false;
          }
        }
      }
    }

    return true;
  }





  const updateShareMetaHandler = async (bucketName, object, type, action, email) => {
    try {
      dispatch('SET_GLOADING', true);

      const resData = await updateShareMetadata(
        BACKEND_URL, 
        localStorage.getItem('token'),
        bucketName,
        object.Key,
        type,
        action,
        email,
      );

        console.log(resData);


        if (resData.data) {

          dispatch('SET_OBJECTACTION', {
            actionType: 'update-share-metadata',
            delete: true,
            add: true,
            result: resData.data,
          });

          const noOldObjectMetaList = allObjectsMetaList.filter(element => {
            return element.Key !== object.Key;
          });
          
          const addedList = noOldObjectMetaList.concat({
            Key: resData.data.Key,
            objectMeta: resData.data.objectMeta,
          });

          dispatch('SET_ALLOBJECTSMETALIST', addedList);


          if (action === 'add') {
            const isInSharingUserList = sharingUsers.find(user => {
              return user.email === email;
            });

            if (!isInSharingUserList) {
              const addedUserList = sharingUsers.concat(resData.data.userInfo);
              dispatch('SET_SHARINGUSERS', addedUserList);
              // getSharingUsersHandler();
            }
          }

          setClickedActionInfoHandler(null);
          
          dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'share data update success',
            message: '',
          });
    
          setTimeout(() => {
            dispatch('CLEAR_NOTIFICATION');
          },1000 * 3);

        }

        dispatch('SET_GLOADING', false);

    
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `share data update failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };


  let fileShareModalBody;

  fileShareModalBody = (
    <div>
      {/* <button onClick={() => {
        const validateResult = validateShareInputs(shareInputs, shareInfoMeta);
        console.log('validateResult: ', validateResult ? 'valid' : 'in-valid')
      }}
      >
        validate-test
      </button> */}

      <div className={classes.fileShareModalContentRow}>
        <FileSharingUsers 
          object={object}
          shareInfoMeta={shareInfoMeta}
        />
      </div>

      {/* <div className={classes.fileShareModalContentRow}>
        <div>
          {getOriginalFileNameFromKey(object.Key)}
        </div>
      </div> */}

      <div className={classes.fileShareModalContentRow}>
        <div>
          {t('fileAction.12', 'Manage File Share User')}
        </div>
      </div>
      <div className={classes.fileShareModalContentRow}>
        <div className={classes.fileShareModalContentMessage}>
        {t('fileAction.13', "For sharing a file with other users, the users need to accept your share invitation. The Notification will be shown in user's page. Please make sure that the users has account")} 
          {' '}
          in Kura Storage.  
        </div>
      </div>

      <div className={classes.fileShareModalContentRow}>
        <div className={classes.fileShareModalContentButtons}>
          <select className='selectBase'
            // name="pets" 
            // id="pet-select"
            onChange={(event) => { 
              setShareInputsHandler('type', event.target.value);
            }}
          >
            <option value="">
              {t('fileAction.14', 'Share type select')}
            </option>
            <option value="read">
              {t('fileAction.15', 'read')} (read)
            </option>
            <option value="write">
              {t('fileAction.16', 'write')} (write)
            </option>
          </select>
          <select className='selectBase'
            // name="pets" 
            // id="pet-select"
            onChange={(event) => { 
              setShareInputsHandler('action', event.target.value);
            }}
          >
            <option value="">
              {t('fileAction.17', 'Add or delete')}
            </option>
            <option value="add">
              {t('fileAction.18', 'add')}
            </option>
            <option value="delete">
              {t('fileAction.19', 'delete')}
            </option>
          </select>
        </div>
      </div>

      <div className={classes.fileShareModalContentRow}>
        <div className={classes.fileShareModalContentLabel}>
          <label>
            {t('fileAction.20', 'Email address of share user')}
          </label>
        </div>
        <div>
          <input className='inputBase'
            style={{ maxWidth: "20rem"}}
            type="text"
            onChange={(event) => { 
              setShareInputsHandler('email', event.target.value); 
            }}
            // value={shareEmailInput}
          />
        </div>
      </div>

      <div className={classes.fileShareModalContentRow}>
        <div className={classes.fileShareModalContentButtons}>
          {/* <button className='btnBase'
            disabled={isLoading}
            onClick={(event) => {
              if (!isLoading) {
                setClickedActionInfoHandler(null)
              }
            }}
          >
            cancel-share
          </button> */}
          <button className='btnBase'
            disabled={isLoading || !isValidInputs}
            onClick={(event) => {
              if (!isLoading) {
                setShowConfirm(true);
              }
            }}
          >
            {shareInputs.action.value !== 'delete' && (
              <span>{t('fileAction.21', 'File share')}</span>
            )}
            {shareInputs.action.value === 'delete' && (
              <span>{t('fileAction.22', 'Delete share user')}</span>
            )}
          </button>
        </div>
      </div>

      {showConfirm && (
        <div>
          <div className={classes.fileShareModalContentRow}>
            {shareInputs.action.value} {shareInputs.email.value} ?
          </div>
          <div className={classes.fileShareModalContentButtons}>
            <button className='btnBase'
              disabled={isLoading}
              onClick={(event) => {
                if (!isLoading) {
                  setShowConfirm(false);
                }
              }}
            >
              {t('fileAction.23', 'Cancel')}
            </button>
            <button className='btnBase'
              disabled={isLoading || !isValidInputs}
              onClick={(event) => {
                if (!isLoading) {
                  updateShareMetaHandler(
                    bucketName,
                    object,
                    shareInputs.type.value,
                    shareInputs.action.value,
                    shareInputs.email.value,
                  );
                }
              }}
            >
            {shareInputs.action.value !== 'delete' && (
              <span>{t('fileAction.21', 'File share')}</span>
            )}
            {shareInputs.action.value === 'delete' && (
              <span>{t('fileAction.22', 'Delete share user')}</span>
            )}
            </button>
          </div>
        </div>
        )}
    </div>
  )


  return (
    <Fragment>
      {fileShareModalBody}
    </Fragment>
  );
}

export default FileShareModalContent;
