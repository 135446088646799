// import logo from './logo.svg';

import { Fragment, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
// import { useEffect, useState } from 'react';
// import { BrowserRouter as Switch, Route, useHistory, Link } from "react-router-dom";

import AboutPage from './pages/AboutPage/AboutPage';
import AdminPage from './pages/AdminPage/AdminPage';
import AuthCheck from './components/Auth/AuthCheck';
import Backdrop from './components/Backdrop/Backdrop';
// import FileUpload from './components/FileUpload';
import Bucket from './components/BucketComponents/Bucket/Bucket';
import BucketObjects from './components/BucketComponents/BucketObject/BucketObjects';
// import FileFolderMove from './components/FileFolderMove';
// import FileMove from './components/FileMove';
// import Folder from './components/BucketComponents/Folder/Folder';
import GetAuth from './components/Auth/GetAuth';
import GetLoginUsers from './components/Auth/GetLoginUsers';
import GetDeferredPrompt from './components/UI/GetDeferredPrompt';
import GetWindowData from './components/UI/GetWindowData';
import Layout from './components/Layout/Layout';
import Loader from './components/Loader/Loader';
import NotPageFound from './pages/NotPageFound/NotPageFound';
import PrivacyPolicy from './pages/PlivacyPolicy/PrivacyPolicy';
import SizeManagePage from './pages/SizeManagePage/SizeManagePage';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import UserInfo from './pages/UserInfo/UserInfo';
import Contact from './pages/Contact/Contact';
// import FileUploadSocket from './components/BucketComponents/ObjectControl/FileUploadSocket';

import Register from './pages/Subscription/Register';
import Subscribe from './pages/Subscription/subscribe/Subscribe';
import Cancel from './pages/Subscription/cancel/Cancel';
import ChangePlan from './pages/Subscription/change-plan/ChangePlan';
import Account from './pages/Subscription/account/Account';
import Prices from './components/subscription/prices/Prices';

import GetAllObjects from './components/BucketComponents/BucketObject/GetAllObjects';
import GetObjectMetadata from './components/BucketComponents/ObjectMetadata/GetObjectMetadata';
// import GetReservedPrefixes from './components/BucketComponents/BucketObject/GetReservedPrefixes';
import GetSubscriptionData from './components/subscription/GetSubscriptionData';
import HeadMetaTag from './components/UI/HeadMetaTag';

import ServiceBuckets from './components/BucketComponents/ServiceBuckets/ServiceBuckets';

import ToolsDataSocket from './components/BucketComponents/ToolsData/ToolsDataSocket';
import UserSocketConnect from './components/SocketConnect/UserSocketConnect';

import ShareProcess from './components/BucketComponents/ShareBuckets/ShareProcess';

import AdminBucketObjectList from './components/Admin/AdminBucketObject/AdminBucketObjectList';
// import BucketObjectAllList from './components/BucketObjectAllList';

import GetShareInfo from './components/BucketComponents/ShareBuckets/GetShareInfo';
import SetUserNameData from './components/Auth/SetUserNameData';

import { useStore } from './hook-store/store';

import { 
  getCustomerDataSubscriptions, 
  // getCurrentSubscription, 
  // fetchPrices 
} from './utils/stripe';

import './App.css';



const lsUuid = localStorage.getItem('lsUuid') ? localStorage.getItem('lsUuid') : '';



//// dev urls
// export const BACKEND_URL = process.env.REACT_APP_DEV_BACKEND_URL;
// export const loginPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
// export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_TEST_BASIC;
// export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_TEST_MINI;
// export const kuraToolsLink = process.env.REACT_APP_DEV_KURATOOLS_URL;
// export const TOOLSBACKEND_URL = process.env.REACT_APP_DEV_KURATOOLS_BACKENDURL;
// export const USERACCOUNTPAGE_URL = 'http://localhost:8506';
// export const textAudioToolsLink = 'http://localhost:8504';


//// test deploy urls
// export const BACKEND_URL = process.env.REACT_APP_TEST_BACKEND_URL;
// export const loginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
// export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_BASIC;
// export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_MINI;
// export const kuraToolsLink = process.env.REACT_APP_TEST_KURATOOLS_URL;
// export const TOOLSBACKEND_URL = process.env.REACT_APP_TEST_KURATOOLS_BACKENDURL;
// export const USERACCOUNTPAGE_URL = 'https://casauthsso-userpage-test.web.app';
// export const textAudioToolsLink = 'https://hf-text-to-speech-test-app.web.app';


//// deploy urls
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const loginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
export const signupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
export const subscriptionBasicPriceId = process.env.REACT_APP_SUBPRICE_BASIC;
export const subscriptionMiniPriceId = process.env.REACT_APP_SUBPRICE_MINI;
export const kuraToolsLink = process.env.REACT_APP_KURATOOLS_URL;
export const TOOLSBACKEND_URL = process.env.REACT_APP_KURATOOLS_BACKENDURL;
export const USERACCOUNTPAGE_URL = 'https://useraccount.spaceeight.net';
export const textAudioToolsLink = 'https://kura-text-audio-tools.spaceeight.net';



function App() {

  const [store, dispatch] = useStore();
  const { 
    isAuth, 
    gLoading, 
    srvLoading,
    metaLoading,
    isAdmin, 
    isAllApis,
    socket,
  } = store.uiStore;
  const { isStripeApi } = store.stripeData;
  const { isServiceBucketsApi } = store.serviceBucketsStore;

  const bucketName = store.bucketObjectStore.bucketName;

  console.log('store-App.js', store, isStripeApi);
  // console.log('isAuth bucketname', isAuth, bucketName);


  const userLoginEmail = localStorage.getItem("email")
  ? localStorage.getItem("email")
  : "";

  useEffect(() => {
    if (isServiceBucketsApi && isStripeApi) {
      dispatch('SET_ISALLAPIS', true);
    } else {
      dispatch('SET_ISALLAPIS', false);
    }
  }, [isStripeApi, isServiceBucketsApi]);


  useEffect(() => {
    const lsCustomerEmail = localStorage.getItem('customerEmail');
    
    if (userLoginEmail && lsCustomerEmail) {
      const token = localStorage.getItem('token');
    
      getCustomerDataSubscriptions(token, userLoginEmail)
        .then(result => {
          console.log('get sub result', result);

          if (result.data.length > 0) {
            const subs = result.data;
            // dispatch('SET_SUBSCRIPTIONS', subs);

            const remeetSubs = subs.filter(sub => {
              return sub.metadata.service === 'kura-storage';
            })

            dispatch('SET_SUBSCRIPTIONS', remeetSubs);
          }

        })
        .catch(err => {
          console.log('get sub error', err);
        });
    }
  }, [userLoginEmail]);

  let routesBody;

  if (isAuth) {
    routesBody = (
      <div>
        <Bucket />

        <Routes>

          <Route path="privacypolicy" 
            element={<PrivacyPolicy />} 
          />
          <Route path="termsofuse" 
            element={<TermsOfUse />} 
          />
          <Route path="about" 
            element={<AboutPage />} 
          />

          <Route path="share-process" 
            element={<ShareProcess />} 
          />

          <Route path="contact" 
            element={<Contact />} 
          />

          {bucketName && isAllApis && (
            <>
              <Route path="/userinfo" 
                element={<UserInfo />} 
              />

              <Route path="/" 
                element={<BucketObjects />} 
              />
  
              <Route path="/sizemanage" 
                element={<SizeManagePage />} 
              />

              {/* <Route path="/subscription"
                element={<Register />}
              /> */}

              <Route path="/subscription/prices"
                element={<Prices />}
              />
              {/* <Route path="/subscription/prices-trial-upgrade">
                <SubscriptionLayout>
                  <PricesTrialUpgrade />
                </SubscriptionLayout>
              </Route> */}
              <Route path="/subscription/subscribe"
                element={<Subscribe />}
              />
                  
              <Route path="/subscription/change-plan"
                element={<ChangePlan />}
              />

              <Route path="/subscription/account"
                element={<Account />}
              />

              <Route path="/subscription/cancel"
                element={<Cancel />}
              />
            </>
          )}
          


          {isAdmin && (
            <>
              <Route path="admin" 
                element={<AdminPage />} 
              />
              <Route path="admin/object-list" 
                element={<AdminBucketObjectList />} 
              />
            </>
          )}

  
          <Route path="*" 
            element={<NotPageFound />} 
          />
    

          {/* <Route path="*" 
            element={<NotPageFound />} 
          /> */}

        </Routes>

      </div>

    )
  }
  else {
    routesBody = (
    <div>
      <Routes>

        <Route path="privacypolicy" 
          element={<PrivacyPolicy />} 
        />
        <Route path="termsofuse" 
          element={<TermsOfUse />} 
        />
        <Route path="about" 
          element={<AboutPage />} 
        />
        
        <Route path="*" 
          element={<NotPageFound />} 
        />
      </Routes>
    </div>
    )
  }


  return (
    <Fragment>

      <BrowserRouter>
        <Layout>
          {(gLoading || srvLoading || metaLoading) && (
            <div>
              <Backdrop 
                zIndex={'500'}
                onCancel={() => { 
                  if (!gLoading) {
                    // setClickedObjectKeyHandler(''); 
                  }
                }}
              />
              <span className='gLoader'>
                <Loader />
              </span>
            </div>
          )}

          {isStripeApi && (
            <div>
              {routesBody}
            </div>
          )}

          {!isStripeApi && (
            <div>
              <NotPageFound />
            </div>
          )}


          <GetWindowData />
          <GetDeferredPrompt />
          <HeadMetaTag />
  
          <GetAuth />
          <AuthCheck />
          
          <div style={{display:'none'}}>
            <GetLoginUsers />
          </div>

        </Layout>

        {/* <Register />
        <GetAllObjects /> */}

        {isAuth && (
          <div>
            <Register />
            <GetAllObjects />
            <GetObjectMetadata />
            {/* <GetReservedPrefixes /> */}
            <GetSubscriptionData />

            {/* <FileUploadSocket /> */}

            <ServiceBuckets />

            <GetShareInfo />
            <SetUserNameData />

            <UserSocketConnect />
            <ToolsDataSocket />
          </div>
        )}

      </BrowserRouter>
    </Fragment>
  );
}

export default App;
