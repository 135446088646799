import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../hook-store/store';

import { createFileMark } from '../../../utils/bucket/bucket-ui-util';
import { marks } from '../../../utils/marks';

import { BACKEND_URL } from '../../../App';

import classes from './SelectedObjects.module.css';

function SelectedObjects(props) {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedPrefix = store.bucketObjectStore.selectedPrefix;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;

  const gLoading = store.uiStore.gLoading;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);




  return (
    <Fragment>
      {selectedFileKeys.length > 0 && (
        <div className={classes.selectedObjectList}>
          <div>{t('selectedObject.05', 'Selected Files')}</div>
          <div>
            {selectedFileKeys.map(key => {

              const keyObject = topLevels.find(object => {
                return object.Key === key;
              });

              const fileMark = createFileMark(keyObject);    
              const timeEndIndex = key.split('/')[key.split('/').length - 1].indexOf('Z');
              const originalName = key.split('/')[key.split('/').length - 1].substring(timeEndIndex+2);
              
              return (
                <div key={key}>
                  {fileMark}{' '}
                  {/* {originalName} */}
                  {decodeURI(originalName)}
                </div>
              );

              // if (key.split('/').length === 1) {
              //   const timeEndIndex = key.indexOf('Z');
              //   const originalName = key.substring(timeEndIndex+2);
                
              //   return (
              //     <div key={key}>
              //       {fileMark} {originalName} {' '}
              //     </div>
              //   );
              // } 
              // else {
              //   const timeEndIndex = key.split('/')[key.split('/').length - 1].indexOf('Z');
              //   const originalName = key.split('/')[key.split('/').length - 1].substring(timeEndIndex+2);
                
              //   return (
              //     <div key={key}>
              //       {fileMark} {originalName}
              //     </div>
              //   );
              // }

            })}
          </div>
        </div>
      )}

      {movePrefixes.length > 0 && (
        <div className={classes.selectedObjectList}>
          <div>{t('selectedObject.06', 'Selected Folders')}</div>
          <div>
            {movePrefixes.map(mPx => {
              return (
              <span>
                {marks.folderMark}{' '}
                {/* {mPx.split('/')[mPx.split('/').length - 2]} */}
                {decodeURI(mPx.split('/')[mPx.split('/').length - 2])}
                {' '}
              </span>
              );
            })}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default SelectedObjects;
