import { useState, useEffect } from 'react';
// import _ from 'lodash';
// import numeral from 'numeral';
import moment from 'moment';

import BucketObjectMenu from '../BucketObject/BucketObjectMenu';

import { useStore } from '../../../hook-store/store';
import { 
  getOriginalFileNameFromKey,
  getPrefixFromKey,
} from '../../../utils/bucket/bucket-object-util';
import { 
  createFileMark,
} from '../../../utils/bucket/bucket-ui-util';

import { marks } from '../../../utils/marks';

import classes from '../BucketObject/BucketObjectList.module.css';
import { update } from 'lodash';

// import ObjectControl from '../ObjectControl/ObjectControl';

function ObjectAccess(props) {

  const {
    getAllBucketObjects,
    storeObjectAccessHandler,
    createToplevelsHandler,
  } = props;

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefix = store.bucketObjectStore.movePrefix;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;

  const accessList = store.bucketObjectStore.accessList;
  const allBucketObjects = store.bucketObjectStore.allBucketObjects;

  const gLoading = store.uiStore.gLoading;
  const windowValues = store.uiStore.windowValues;

  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  useEffect(() => {
    // setClickedObjectKeyHandler(''); 
    // setClickedActionInfo(null);
  },[topLevels]);


  const objectNameClickHandler = (object, folderName) => {
    // getAllBucketObjects(bucketName, object.Key);
    if (folderName) {
      createToplevelsHandler(object.Key, allBucketObjects);  
    } else {
      createToplevelsHandler(getPrefixFromKey(object.Key), allBucketObjects); 
    }
    // storeObjectAccessHandler(object.Key);
    dispatch('SET_DISPLAYTABLE', '');
    // storeObjectAccessHandler(object.Key);
  }


  
  const updatedList = [];
    
  for (const element of accessList) {

    const isInAllList = allBucketObjects.find(obj => {
      return obj.Key === element.Key;
    });

    const isInList = updatedList.find(obj => {
      return obj.Key === element.Key; 
    });

    if (isInAllList && !isInList) {
      updatedList.push({
        ...isInAllList,
        time: element.time,
      });
    }
  }

  // console.log(updatedList);








  let topLevelsBody;


  if (topLevels.length < 1) {
    topLevelsBody = (
      // <tbody>
      //   <tr>
      //     <td>no-items</td>
      //   </tr>
      // </tbody>
      <div>no-items</div>
    );
  }
  if (topLevels.length === 1 && topLevels[0].Key.endsWith('/')) {
    topLevelsBody = (
      // <tbody>
      //   <tr>
      //     <td>no-items</td>
      //   </tr>
      // </tbody>
      <div>no-items</div>
    );
  }

  // if (accessList &&  accessList.length > 0 && ( topLevels.length === 1 && !topLevels[0].Key.endsWith('/') ) || 
  //   topLevels.length >= 2 
  // ) {

  // if (accessList &&  accessList.length > 0) {
  if (updatedList.length > 0) {
    topLevelsBody = (
      <tbody>
        {/* {topLevels.map((object, index) => { */}
        {/* {accessList.map((object, index) => { */}
        {updatedList.map((object, index) => {
          // console.log('accessList object', object)






        const isInSelectedFileKeys = selectedFileKeys.find(key => {
          return key === object.Key;
        });

        const isInMovePrefixes = movePrefixes.find(px => {
          return px === object.Prefix;
        });
        // console.log(isInSelectedFileKeys);

        const fileActionElementId = `file-action-${object.Key}`;
        

        let folderActionElementId= `folder-action-${object.Key}`;
        
        // if (object.Prefix) {
        //   folderActionElementId= `folder-action-${object.Prefix}`;
        // }




        let folderName = '';
        let fileName = '';
        let objectNameBody;

        // let folderName = object.key.substring(currentPrefix.length, object.key.length -2);
        if (object.Key && object.Key.endsWith('/')) {
          folderName = object.Key.substring(0, object.Key.length -1);
        }

        if (object.Key && !object.Key.endsWith('/')) {
          fileName = getOriginalFileNameFromKey(object.Key);
        }

        if (folderName) {
          objectNameBody = (<span>
              {marks.folderMark}{' '}
              {/* {folderName} */}
              {decodeURI(folderName)}
            </span>
          );
        }

        if (fileName) {
          let fileTitle = '/';

          if (object.Key.split('/').length > 1) {
            fileTitle = getPrefixFromKey(object.Key);
          }
          
          objectNameBody = (
            <span title={fileTitle}>
              {createFileMark(object)}{' '}
              {/* {fileName} */}
              {decodeURI(fileName)}
            </span>
          );
        }


        return (
          <tr key={index} >
            <td>
              {/* <span                         
                onClick={() => { 
                  if (!isLoading) {
                    folderClickSelectHandler(isInMovePrefixes, object.Key);
                  }
                }}
              >
                {isInMovePrefixes ? 
                  <span>
                    {checkMark}
                  </span> 
                : 
                  <span>
                    {noCheckMark}
                  </span>
                }                      
              </span> */}
            </td>
            <td>
              {/* {folderNameBody} */}
              <span
                onClick={() => {    
                  if (!isLoading) {
                    objectNameClickHandler(object, folderName);
                  }            
                }}
              >
                {objectNameBody}
              </span>
            </td>
            <td>
            </td>
            <td>
            </td>
            {(window.innerHeight >= 480 && window.innerWidth >= 480) && (
              <td>
                <span>
                  {/* {object.time} */}
                  {moment(object.time).format("YYYY-MM-DD h:mm a")}
                  </span>
              </td>
            )}

          </tr>
        );
      })}
      </tbody>
    );
  }




  return (
    <div className={classes.objectTableView}>
      {/* <div>recent-visit-object-component</div> */}
        {/* <span>abcd-efg,  &#10006; &#x2715; &#215;</span><span id="testid">bucket-object-list</span> */}
      {/* {isLoading && <div>loading...loading...</div>} */}
      {/* <ObjectControl /> */}
      {/* <div onClick={selectAllObjectsHandler}>add-all-test</div>
      <div onClick={deSelectAllObjectsHandler}>remove-all-test</div> */}

      {/* <PrefixLine 
          getBucketCommonPrefixes={getBucketCommonPrefixes} 
          getAllBucketObjects={getAllBucketObjects}
          isLoading={isLoading}
      /> */}

      {/* <div style={{display:"flex", justifyContent:"space-between"}}>
        <div></div>
        <SelectedObjectsControll />
      </div> */}

      <div className={classes.objectTableContainer}>
        
        <div className={classes.objectTableMenu}>
          <BucketObjectMenu />
        </div>

        <table className={classes.objectTable}>
          <thead>
            <tr>
              <th>
                {/* {isAllSelected && (
                <span
                  onClick={() => {
                    if (!isLoading) {
                      deSelectAllObjectsHandler();
                    }
                  }}
                >
                  {marks.checkMark}
                </span>
                )}
                {!isAllSelected && (
                <span
                  onClick={() => {
                    if (!isLoading) {
                      selectAllObjectsHandler();
                    }
                  }}
                >
                  {marks.noCheckMark}
                  </span>
                )} */}
              </th>
              <th>name</th>
              <th></th>
              <th></th>
              {(window.innerHeight >= 480 && window.innerWidth >= 480) && (
                <th>last visit</th>
              )}
            </tr>
          </thead>
          {topLevelsBody}
        </table>
      </div>
    </div>
  );
}

export default ObjectAccess;
