import { Fragment, useState } from 'react';
// import { useLocation } from 'react-router-dom';

import Backdrop from '../../Backdrop/Backdrop';
import GetLoginUsers from '../../Auth/GetLoginUsers';
import ModalPosition from '../../Modal/ModalPosition';

import { useStore } from '../../../hook-store/store';
import { marks } from '../../../utils/marks';

import classes from './NavUser.module.css';

const userModalStyle = {
  position: 'fixed',
  top: '3.5rem',
  right: '0.1rem',
  // width: '15rem',
  maxWidth: '80%',
  // height: '15rem',
  maxHeight: '50vh',
  overflowY: 'auto',
  border: '1px solid var(--color-gray-100)',
  borderRadius: 'var(--border-radius-small)',
  background: 'var(--color-gray-20)',
  boxShadow: 'var(--shadow-small)',
  zIndex: 100,
  // padding: '0.5rem',
};

function NavUser(props) {
  // console.log('Layout.js-props', props);

  const [store, dispatch] = useStore();
  // console.log('store- Layout.js', store);
  // const showToggleNav = store.uiStore.showToggleNav;
  const { 
    gLoading,
    userNameData,
   } = store.uiStore;

  const [showUserModal, setShowUserModal] = useState(false); 

  const lsEmail = localStorage.getItem('email');

  const showUserModalHandler = () => {
    setShowUserModal(true);
  }

  const hideUserModalHandler = () => {
    setShowUserModal(false);
  }

  return (
    <Fragment>
      <div className={classes.navUserIcon}
        // onClick={() => {
        //   if (!gLoading && showUserModal) {
        //     hideUserModalHandler()
        //   }
        //   if (!gLoading && !showUserModal) {
        //     showUserModalHandler();
        //   }
        // }}
      >
        {!lsEmail && (
          <span
            onClick={() => {
              if (!gLoading && showUserModal) {
                hideUserModalHandler()
              }
              if (!gLoading && !showUserModal) {
                showUserModalHandler();
              }
            }}
          >
            {marks.userMrak}
          </span>
        )}
        {lsEmail && (
          <div className={classes.navUserUserImageContainer}
            onClick={() => {
              if (!gLoading && showUserModal) {
                hideUserModalHandler()
              }
              if (!gLoading && !showUserModal) {
                showUserModalHandler();
              }
            }}
          >
            {userNameData?.imageUrl && (
              <img className={classes.navUserUserImage}
                src={userNameData.imageUrl}
                alt='user'
              />
            )}
            {!userNameData?.imageUrl && (
              <span className={classes.navUserUserNoImage}>
                {marks.userMrak}
              </span>
            )}
            <span className={classes.navUserUserName}>
              {lsEmail.slice(0,4)}
            </span>
          </div>
        )}

        <div>
          <span style={showUserModal ? { display: 'block', fontSize: "1rem"} : { display: 'none'}}>
            {showUserModal && (
              <Backdrop 
                zIndex={'90'}
                // backgroundColor={'rgba(0, 0, 0, 0.1)'}
                backgroundColor={'rgba(0, 0, 0, 0.1)'}
                onCancel={() => { 
                  hideUserModalHandler()
                  // if (!isLoading) {
                  // }
                }}
              />
            )}
            <ModalPosition
              modalStyle={userModalStyle}
            >
              <GetLoginUsers />
            </ModalPosition>
          </span>
        </div>

      </div>
      {/* {showUserModal && (
        <ModalPosition
          modalStyle={userModalStyle}
        >
          <GetLoginUsers />
        </ModalPosition>
      )} */}

      
    </Fragment>
  );
}

export default NavUser;
