import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Backdrop from '../../../Backdrop/Backdrop';
import FolderAddTrasboxMeta from './FolderAddTrashboxMeta';
import ModalConfirm from '../../../Modal/ModalConfirm';
// import ModalPosition from '../../Modal/ModalPosition';


import { useStore } from '../../../../hook-store/store';
import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
import { 
  getObjectMetadata,
  addTrashMetadata,
} from '../../../../utils/bucket/object-metadata-util';

import { BACKEND_URL } from '../../../../App';

import { marks } from '../../../../utils/marks';
import classes from './FolderActions.module.css';

function FolderActions(props) {

  const {
    deleteFolderHandler,
    folderClickSelectHandler,
    setMovePrefixesHandler,
    setClickedActionInfoHandler,
    setClickedObjectKeyHandler,
    clickedActionInfo,
    object,
    isInMovePrefixes,
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');


  const [store, dispatch] = useStore();

  const { 
    bucketName,
    allBucketObjects,
    currentPrefix,
    topLevels,
   } = store.bucketObjectStore;

   const { allObjectsMetaList } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const addFolderTrashboxMetaHandler = async(bucketName, currentPrefix, folderObjects, allObjectsMetaList) => {
    try {

      dispatch('SET_GLOADING', true);
      
      let addMetaResults = [];
      let forMetaUpdateList = allObjectsMetaList;

      const nowTime = Date.now();

      for (const object of folderObjects) {

        let oldMetadata;


        const objectMeta = allObjectsMetaList.find(element => {
          return element.Key === object.Key;
        });
  
        if (objectMeta) {
          oldMetadata = objectMeta
        }
          
        // const objectMeta = await getObjectMetadata(
        //   BACKEND_URL,
        //   localStorage.getItem('token'),
        //   bucketName,
        //   object.Key,
        // );
        
        // if (objectMeta) {
        //   oldMetadata = objectMeta.data;
        // }
    
  
      console.log(oldMetadata);
  
      if (oldMetadata) {
        console.log('oldMetadata', oldMetadata);
        const parsedOldMeta = JSON.parse(oldMetadata.objectMeta.Metadata.metastring);
        const updateMeta = {
          ...parsedOldMeta,
          location: 'trashbox',
          trashMoveTime: nowTime,
        };
  
        console.log(updateMeta);
  
        let storePath = bucketObjectUtils.getPrefixFromKey(object.Key);
          
        if (object.Key.endsWith('/')) {
          const separateList = object.Key.split('/');
          separateList.pop();
          separateList.pop();

          
          if (separateList.length > 0) {
            storePath = separateList.join('/') + '/';
          } else {
            storePath = '';
          }
        } 

        const resData = await addTrashMetadata(
            BACKEND_URL,
            localStorage.getItem('token'),
            bucketName,
            storePath,
            object.Key,
            updateMeta,
            dispatch,
          );
        
          console.log(resData);

          if (resData.data) {
            addMetaResults =  addMetaResults.concat(resData.data);
            
            const noOldObjectMetaList = forMetaUpdateList.filter(element => {
              return element.Key !== object.Key;
            });
          
            forMetaUpdateList = noOldObjectMetaList.concat({
              Key: resData.data.Key,
              objectMeta: resData.data.objectMeta,
            });

            dispatch('SET_ALLOBJECTSMETALIST', forMetaUpdateList);
          }

        }
        
      }

      dispatch('SET_OBJECTACTION', {
        actionType: 'update-metadata',
        delete: true,
        add: true,
        result: addMetaResults,
      });
        

      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'folder moved to trash box',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'folder move failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };

  // let deleteFolderModalBody;

  // if (clickedActionInfo && clickedActionInfo.name === 'delete-object') {
  //   deleteFolderModalBody = (
  //     <ModalConfirm 
  //     onClose={() => { setClickedActionInfoHandler(null); }}
  //     onAccept={() => { deleteFolderHandler(bucketName, object.Key); }}
  //     isLoading={isLoading}
  //   >
  //     {t('folderAction.01', 'delete folder')} ?
  //   </ModalConfirm>
  //   )
  // }


  let addTrashMetaModalBody;

  if (clickedActionInfo && clickedActionInfo.name === 'folder-add-trashbox-meta') {
    addTrashMetaModalBody = (
      <div>
        <Backdrop 
          zIndex={'110'}
          // backgroundColor={'rgba(0, 0, 0, 0.1)'}
          onCancel={() => { 
            if (!isLoading) {
              setClickedActionInfoHandler(null);
            }
          }}
        />  
        <ModalConfirm 
          onClose={() => { setClickedActionInfoHandler(null); }}
          onAccept={() => { 
            addFolderTrashboxMetaHandler(
              bucketName,
              currentPrefix,
              bucketObjectUtils.getFolderObjectsFromAllList(object.Key, allBucketObjects),
              allObjectsMetaList
            );
          }}
          isLoading={isLoading}
        >
          {t('trash.13', 'move to trash box')} ?
        </ModalConfirm>
      </div>
    )
  }


  const folderName = bucketObjectUtils.getFolderNameFromKey(object.Key);

  let folderActionsBody;

  if (object.Key) {
    folderActionsBody = (
        <span className={classes.objectActionModal}>
          {/* <button 
            disabled={isLoading}
            onClick={() => { 
              if (!isLoading) {
                if (isInMovePrefixes) {
                  setMovePrefixesHandler(object.Key, 'delete');
                }
                else {
                  setMovePrefixesHandler(object.Key, 'add');
                }
              }
            }}
          >
            {isInMovePrefixes ? 'deselect-for-move' : 'select-for-move'}
          </button>
  
          {isInMovePrefixes && 'selected-for-move'} */}
  
          {/* <button className={classes.objectActionListItem}
            name="delete-object"
            disabled={isLoading}
            onClick={(event) => { 
              if (!isLoading) {
                setClickedActionInfoHandler(event, object.Key);
              }
            }}
          >
            {marks.trashMark} {t('folderAction.01', 'delete folder')}
          </button> */}
          
        <span className={classes.objectActionListItem}>
          {/* {folderName} */}
          {decodeURI(folderName)}
        </span>

        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name="change-folder-name"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
            }
          }}
        >
          {marks.editMark} {t('folderAction.02', 'change folder name')}
        </button>
 
        <FolderAddTrasboxMeta 
          setClickedActionInfoHandler={setClickedActionInfoHandler}
          object={object}
        />
      </span>
    );
  }



  return (
    <Fragment>
      {/* {deleteFolderModalBody} */}
      {addTrashMetaModalBody}
      {folderActionsBody}
    </Fragment>
  );
}

export default FolderActions;
