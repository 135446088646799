import React, { Fragment, useEffect, useState } from 'react';

// import openSocket from 'socket.io-client';
import { io } from "socket.io-client";

import { useStore } from '../../../hook-store/store';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util'
// import { socket } from '../../SocketConnect/UserSocketConnect';
import { BACKEND_URL, TOOLSBACKEND_URL } from '../../../App';

// import classes from './PostSelect.module.css';

function ToolsDataSocket(props) {
  // const { } = props;
  // console.log('socket', socket)

  const [store, dispatch] = useStore();
  const { allBucketObjects } = store.bucketObjectStore;
  const { allObjectsMetaList } = store.objectMetadataStore;
  const { shareObjects } = store.shareObjectStore;
  // const { bucketName, currentPrefix } = store.bucketObjectStore;
  const { 
    isAuth,
    socket,
  } = store.uiStore;

  // const [shareObjectUpdateData, setShareObjectUpdateData] = useState();

  useEffect(() => {
      // const socket = openSocket(BACKEND_URL);
      // const socket = io(BACKEND_URL, {
      //   // autoConnect: false
      // });

      if (!socket) {
        return
      }

      // const userId = localStorage.getItem('userId');

      socket.on("connect", () => {
        console.log('connect ToolsDataSocket, socket.id', socket.id);
        // dispatch('SET_SOCKETID', socket.id);

        // socket.emit('user-id', {
        //   socketId: socket.id,
        //   userId: userId,
        //   token: localStorage.getItem('token'),
        // });
      });


      //// notify to file owner, when owner's tools file update case.
      socket.on('tools-file-update', data => {
        console.log('tools-file-update data', data);
        
        if (isAuth) {
          dispatch('SET_OBJECTACTION', {
            actionType: 'tools-file-update',
            delete: true,
            add: true,
            result: null,
          });
        }

      });


      //// notify to share file user (not owner), when tools file update case.
      socket.on('tools-share-file-update', data => {
        console.log('tools-share-file-update data', data);
        
        // setShareObjectUpdateData(data.data);

        //// update share file list

        if (isAuth) {
          dispatch('SET_OBJECTACTION', {
            actionType: 'tools-share-file-update-notification',
            // delete: false,
            // add: false,
            // result: data,
          });
        }
      });

      return () => {
        socket.off('connect');
        socket.off('tools-file-update');
        socket.off('tools-share-file-update');
      }
      
  },[socket]);


  // useEffect(() => {
  //   if (shareObjectUpdateData) {
  //     updateShareObjecsAfterUpdateHandler(shareObjectUpdateData);
  //   }
  // },[shareObjectUpdateData]);


  // const updateShareObjecsAfterUpdateHandler = (data) => {
  //       const objectData = data.objectData;
  //       const objectMeta = data.objectMeta;
  //       const oldKey = data.oldKey;

  //       const shareObjectList = shareObjects;
  //       const oldObjectIndex = shareObjectList.findIndex(object => {
  //           return object.Key === oldKey;
  //       });

  //       if (oldObjectIndex >= 0) {
  //         shareObjectList[oldObjectIndex] = {
  //           ...objectData,
  //           objectMeta: objectMeta,
  //           shareType: data.objectData.shareType,
  //         };

  //         console.log(shareObjectList);

  //         dispatch('SET_SHAREOBJECTS', shareObjectList);
  //       }
  // }



 

  return (
    <Fragment></Fragment>
  );
}

export default ToolsDataSocket;