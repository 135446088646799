import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import Loader from "../../../Loader/Loader";

import { useStore } from "../../../../hook-store/store";
import { 
  updateShareMetadataAcceptId,
  getShareOwnerInfoList,
} from '../../../../utils/share-bucket/share-bucket-util';
import { 
  getOriginalFileNameFromKey
} from "../../../../utils/bucket/bucket-object-util";


import { BACKEND_URL } from "../../../../App";

import classes from './ShareNotificationItem.module.css'


const ShareNotificationItem = (props) => {
  const { notify } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth, gLoading } = store.uiStore;
  // const { 
  //   allBucketObjects,
  //   objectAction,
  // } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { 
    shareObjects,
    shareNotification,
    shareOwners,
  } = store.shareObjectStore;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const shareProcessHandler = async (action, shareData) => {
    try { 
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const result = await fetch(BACKEND_URL + '/share-bucket/update-share-bucket-data', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          bucket: shareData.bucket,
          action: action,
          userId: localStorage.getItem('userId'),
        })
      });
  
      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(result, resData);

      if (resData.data && resData.data.shareBuckets && 
        resData.data.shareBuckets.length > 0
      ) {
        addShareAcceptIdHandler(
          shareData.bucket, 
          shareData.key,
          localStorage.getItem('userId'),
          shareData._id,
        );
      }
  
      // setIsLoading(false);
      // dispatch('SET_GLOADING', false);

      return resData;

    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'share file failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };



  const addShareAcceptIdHandler = async (bucket, key, userId, notificationId) => {
    try { 
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await updateShareMetadataAcceptId(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        key,
        userId,
        'add',
        notificationId,
      );

      if (resData) {
        // getUserShareMetaHandler(shareData.userId);

        dispatch('SET_OBJECTACTION', {
          actionType: 'accept-file-share',
          delete: false,
          add: false,
          result: resData.data,
        });

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'share file success. File will be found in menu on top page',
          message: '',
        });
  
        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
          // navigate('/');
          // window.location.reload();
        },1000 * 10);


        const deletedNotifications = shareNotification.shareNotifications.filter(notification => {
          return notification._id !== notificationId;
        });

        dispatch('SET_SHARENOTIFICATION', {
          ...shareNotification,
          shareNotifications: deletedNotifications
        });

        const addedShareObjects = shareObjects.concat(resData.data);
        dispatch('SET_SHAREOBJECTS', addedShareObjects);
      }

      if (resData.ownerInfo) {
        const isOwnerInfo = shareOwners.find(user => {
          return user.userId === resData.ownerInfo.userId;
        });
  
        if (!isOwnerInfo) {
          const addedOwners = shareOwners.concat(resData.ownerInfo);
          dispatch('SET_SHAREOWNERS', addedOwners);
        }
      }
      
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

      return resData;

    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

      if (err.message === 'id-not-found-in-writeIds-and-readIds') {
        dispatch('SHOW_NOTIFICATION', {
          status: 'error',
          title: 'share file failed, File owner might stop file sharing. If you need to share the file, please ask file owner.',
          message: '',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        },1000 * 60);

      } else {
        dispatch('SHOW_NOTIFICATION', {
          status: 'error',
          title: 'share file failed. If you need to share this file, please ask this file owner to share file again.',
          message: '',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        },1000 * 10);
      }
    }
  };



  let shareNotificationItemBody;

  if (notify) {
    shareNotificationItemBody = (
      <div className={classes.shareNotifyItem}>
        <div className={classes.shareNotifyItemRow}>
          {t('shareList.09', 'file name')}: 
          {' '}
          {/* {getOriginalFileNameFromKey(notify.key)} */}
          {decodeURI(getOriginalFileNameFromKey(notify.key))}
        </div>
        <div className={classes.shareNotifyItemRow}>
        {t('shareList.04', 'file owner')}: {notify.ownerEmail} 
        </div>
        <div className={classes.shareNotifyItemRow}>
        {t('shareList.10', 'time')}: {moment(notify.time).format("YYYY-MM-DD h:mm a")}
        </div>
        <div className={classes.shareNotifyItemActions}>
          <button className="btnBase"
            disabled={isLoading}
            onClick={() => {
              if (!isLoading) {
                shareProcessHandler('add', notify);
              }
            }}
          >
            {t('shareList.11', 'accept share invitation')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div>{shareNotificationItemBody}</div>
      {/* {isLoading && (
        <Loader />
      )} */}
    </Fragment>
  );
};

export default ShareNotificationItem;
